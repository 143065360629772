._01_05_ergebnisse {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1180px;
  height: auto;
  max-width: 100%;
  padding-top: 40px;
  padding-bottom: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._01_05_ergebnisse.last {
  display: none;
  padding-bottom: 0px;
}

.prim_content_ergebnisse {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-bottom: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-column-gap: 0px;
  grid-row-gap: 50px;
}

.ergebnisse_item {
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.ergebnisse_item_column {
  width: 40%;
  padding: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.ergebnisse_item_column.right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ergebnisse_item_right {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ergebnisse_item_row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 10px;
}

.ergebnisse_item_infogrid {
  margin-bottom: 20px;
  grid-column-gap: 0px;
  grid-row-gap: 5px;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
}

.ergebnisse_item_themen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-row-gap: 10px;
}

.ergebnisse_item_themen_mobile {
  display: none;
}

.browser {
  position: static;
  left: 0px;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.browser_tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#161a36),
    to(#5c64b7)
  );
  background-image: linear-gradient(180deg, #161a36, #5c64b7);
}

.browser_window {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: transparent;
}

.browser_tab {
  position: relative;
  display: none;
  height: 50px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 0px solid #000;
  background-color: #161a36;
}

.browser_tab_trenner {
  width: 1px;
  background-color: transparent;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(20%, transparent),
    color-stop(50%, #4b4b4b),
    color-stop(80%, transparent)
  );
  background-image: linear-gradient(
    180deg,
    transparent 20%,
    #4b4b4b 50%,
    transparent 80%
  );
}

.image-225 {
  max-height: 300px;
}

@media screen and (max-width: 991px) {
  ._01_05_ergebnisse.last {
    height: auto;
  }
  .prim_button_onlytext.primary.hidden.ergebnisse_item_thema_button {
    display: none;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .prim_content_ergebnisse {
    height: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .ergebnisse_item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ergebnisse_item_column {
    width: auto;
  }

  .ergebnisse_item_infogrid {
    -ms-grid-columns: -webkit-max-content 1fr;
    -ms-grid-columns: max-content 1fr;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
  }

  .ergebnisse_item_themen {
    overflow: auto;
    padding: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
  }
}

@media screen and (max-width: 767px) {
  ._01_05_ergebnisse.last {
    height: auto;
  }

  .ergebnisse_item {
    display: none;
  }

  .ergebnisse_item_column {
    padding-right: 10px;
    padding-left: 10px;
  }
  .ergebnisse_item_themen {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ergebnisse_item_themen_mobile {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .ergebnisse_item_thema_mobile {
    display: none;
    padding: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 20px;
  }

  .ergebnisse_item_thema_mobile_pic {
    max-width: 180px;
  }
  #ergebnisse_item_kunde.w-node-d5798441-e697-32c8-f344-74d35c05e6ce-3d51b530 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #ergebnisse_item_bereich.w-node-_8d24ebfe-82b0-4adf-484f-b132a5c3fe2d-3d51b530 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-d5798441-e697-32c8-f344-74d35c05e6cc-3d51b530 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }
  #w-node-_8d24ebfe-82b0-4adf-484f-b132a5c3fe2b-3d51b530 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  .browser {
    display: none;
  }

  .image-225 {
    max-height: 200px;
  }
}

@media screen and (max-width: 479px) {
  .ergebnisse_item_thema_mobile {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    grid-column-gap: 0px;
    grid-row-gap: 20px;
  }
}
