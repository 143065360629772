/* .lampe {
  position: absolute;
  left: auto;
  top: 0px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 300px;
  background-color: transparent;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  transition: 1s;
} */

/* .lampe_zentrum {
  width: 290px;
  background-color: #010219;
  transition: 1s;
} */

/* .lampe_rechts {
  width: 50px;
  background-image: linear-gradient(80deg, #1b1e3e 40%, transparent 60%);
  transition: 1s;
}

.lampe_links {
  width: 50px;
  background-image: linear-gradient(280deg, #1b1e3e 40%, transparent 60%);
  transition: 1s;
} */

.lampe_aus {
  background: radial-gradient(
    300px 70px at top center,
    #1b1e3e,
    rgba(255, 255, 255, 0.00000001)
  );
  position: absolute;
  left: auto;
  top: 0px;
  right: auto;
  bottom: 0%;
  display: block;
  width: 100vw;
  background-color: transparent;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  transition: 1s;
}

.lampe_aus.enabled {
  opacity: 0;
  transition: 1s;
}

.lampe_an {
  position: absolute;
  left: auto;
  top: 0px;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  opacity: 0;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  transition: 1s;
}

.lampe_an.enabled {
  opacity: 100;
  transition: 1s;
}

@media (min-width: 768px) {
  .lampe_an {
    background: radial-gradient(
      700px 400px at top,
      #5c64b7 20%,
      rgba(255, 255, 255, 0.00000001) 50%
    );
    transition: 1s;
  }
  .lampe_an.enabled {
    background: radial-gradient(
      700px 400px at top center,
      #5c64b7 20%,
      rgba(255, 255, 255, 0.00000001) 50%
    );
    transition: 1s;
  }
}

.lampe_schatten {
  position: absolute;
  left: auto;
  top: -200px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 200px;
  background-color: transparent;
  opacity: 0;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
  transition: 1s;
}

.lampe_schatten.enabled {
  opacity: 100;
  transition: 1s;
}

@media screen and (max-width: 767px) {
  .lampe_an {
    background: radial-gradient(
      100vw 400px at top center,
      #5c64b7 20%,
      rgba(255, 255, 255, 0.00000001) 50%
    );
    transition: 1s;
  }
  .lampe_schatten {
    top: -200px;
    height: 200px;
    transition: 1s;
  }
  .lampe_schatten.enabled {
    top: -200px;
    height: 200px;
    transition: 1s;
  }
}

.lampe_hintergrundlicht {
  background-image: radial-gradient(100% 50% at center, #5c64b7, #010219);
  position: absolute;
  left: auto;
  top: -200px;
  right: auto;
  bottom: auto;
  display: block;
  width: 100vw;
  height: 600px;
  padding-top: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  opacity: 0;
  transition: 1s;
}

.lampe_hintergrundlicht.enabled {
  opacity: 0.5;
  transition: 1s;
}

.lampe_schatten_zentrum {
  width: 290px;
  background-color: #010219;
  transition: 1s;
}
.lampe_schatten_zentrum.enabled {
  width: calc(100vw - 100px);
  transition: 1s;
}
.lampe_schatten_zentrum.enabledls {
  width: calc(100vw - 50px);
  transition: 1s;
}

.lampe_schatten_transitionline {
  position: absolute;
  left: auto;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 300px;
  box-shadow: 1px 1px 10px 4px #010219;
  transition: 1s;
}

.lampe_schatten_transitionline.enabled {
  width: calc(100vw - 80px);
  transition: 1s;
}

.lampe_schatten_transitionline.enabledls {
  width: calc(100vw - 30px);
  transition: 1s;
}

.lampe_schatten_rechts {
  width: 50px;
  background-image: linear-gradient(100deg, #010219 40%, transparent 60%);
  transition: 1s;
}

.lampe_schatten_links {
  width: 50px;
  background-image: linear-gradient(260deg, #010219 40%, transparent 60%);
  transition: 1s;
}

@media screen and (max-width: 479px) {
  .lampe_schatten_zentrum {
    width: 190px;
    transition: 1s;
  }

  .lampe_schatten_rechts {
    width: 25px;
    transition: 1s;
  }

  .lampe_schatten_links {
    width: 25px;
    transition: 1s;
  }
}
