.swipe_animation {
  display: none;
}

@media screen and (max-width: 497px) {
  .swipe_animation {
    position: absolute;
    left: -77px;
    top: -70px;
    right: auto;
    bottom: auto;
    z-index: 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    width: 400px;
    transition: visibility 0s 0.6s, opacity 0.6s linear;
  }

  .swipe_animation.show {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.6s linear;
  }
}
