:root {
  --main-bg-color: #5c64b7;
  --main-bg-color-light: #a1aa9e;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  display: none;
}
::-webkit-scrollbar-thumb {
  background-color: #212121;
  border-radius: 3em;
}
::-webkit-scrollbar-button {
  background-color: #212121;
}
::-webkit-scrollbar-corner {
  background-color: black;
}

@keyframes pulsatedown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(10px);
  }
}
@-webkit-keyframes pulsatedown {
  0% {
    -webkit-transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(10px);
  }
}

.home {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
.ui-loading .ui-loader,
.ui-loader {
  display: none;
}

.intersection {
  background: radial-gradient(300px 70px at top center, #161a36, #010314);
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}
body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  min-height: 100%;
  background-color: #fff;
  overflow-x: hidden;
}

.brand {
  background-image: url("https://uploads-ssl.webflow.com/61c9c74864049fe191583ab9/61c9c914815a3bb2504e9917_Vibrierendes%20M.png");
  background-position: 0px 0px;
  background-size: cover;
}

.slider2 {
  overflow: hidden;
  width: 100vw;
  height: 300px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}

.mask2 {
  overflow: visible;
  width: 20%;
}

.slider2_div {
  width: 100%;
  height: 100%;
  margin: 10px;
  border-radius: 5px;
  background-color: #b92121;
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
}

.slider2_slide {
  margin: 10px;
  padding-bottom: 0px;
}

.slider2_div-v0-2 {
  width: 100%;
  height: 100%;
  margin: 10px;
  border-radius: 5px;
  background-color: #2129b9;
}

.navbar {
  background-color: #e9e9e9;
}

.slider2_divwrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100%;
  padding-right: 20%;
}

.slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.navbar-dark {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #000;
  font-family: Montserrat, sans-serif;
}

.navbar-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.navbar-dark-brand {
  margin-right: 3px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.text-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.nav-link-light {
  padding: 20px 30px;
  background-color: #000;
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.nav-link-light:hover {
  color: #fff;
}

.navbar-dark-nav-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 30px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 0px;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.navbar-dark-nav-button:hover {
  background-color: hsla(0, 0%, 100%, 0.8);
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.navbar-dark-2 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #000;
  font-family: Montserrat, sans-serif;
}

.text-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.list {
  margin: 35px;
  border: 1px solid #000;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border: 1px solid #000;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.list-item-table {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid #000;
  text-align: left;
}

.list-item-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-item-3 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.list-item-4 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.table-content {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 15px;
  font-size: 12px;
}

.header {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 5px solid #000;
}

.heading {
  text-align: center;
}

.section {
  position: absolute;
  left: 21%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 13px;
  padding-top: 0px;
  padding-bottom: 20px;
}

.checkbox_1 {
  width: 52px;
  height: 26px;
  margin-top: 2px;
  margin-right: 20px;
  border-color: #ff0303;
  border-radius: 20px;
  background-color: #919191;
  -webkit-transition: background-color 300ms ease,
    background-position 300ms ease, border-color 300ms ease;
  transition: background-color 300ms ease, background-position 300ms ease,
    border-color 300ms ease;
  font-size: 26px;
}

.div-block-3 {
  width: 100%;
  height: 100%;
  background-color: #7c4d9d;
  box-shadow: -3px 0 3px 0 rgba(116, 116, 191, 0.32);
}

.div-block-4 {
  border-radius: 5px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 16px;
  line-height: 2px;
}

.slider_wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider_toggle_wrapper {
  position: relative;
  overflow: hidden;
  width: 150px;
  height: 55px;
  padding: 5px;
  border-radius: 33px;
}

.slider_off {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  background-color: #7c7c7c;
  line-height: 100%;
}

.slider_on {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: auto;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#68ff58),
    color-stop(0%, #0b6c00),
    to(#68ff58)
  );
  background-image: linear-gradient(90deg, #68ff58, #0b6c00 0%, #68ff58);
}

.slider_on_icon {
  position: relative;
  z-index: auto;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#000)
  );
  background-image: linear-gradient(180deg, #fff, #000);
}

.top-line {
  position: absolute;
  z-index: auto;
  width: 16px;
  height: 2px;
  margin-top: 20px;
  margin-left: 0px;
  padding-top: 7px;
  padding-bottom: 0px;
  padding-left: 40px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bottom-line {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 16px;
  height: 2px;
  margin-top: 20px;
  margin-left: 0px;
  padding-top: 7px;
  padding-left: 40px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.bottom-line-on {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 16px;
  height: 2px;
  margin-top: 20px;
  margin-left: 13px;
  padding-top: 7px;
  padding-left: 29px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.top-line-on {
  position: absolute;
  z-index: auto;
  width: 16px;
  height: 2px;
  margin-top: 27px;
  margin-right: 1px;
  margin-left: 1px;
  padding: 6px 14px 0px 7px;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.slider_off_icon {
  position: relative;
  z-index: 2;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#000)
  );
  background-image: linear-gradient(180deg, #fff, #000);
}

.table_header_slider {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 15px;
  font-size: 12px;
  text-align: center;
}

.list_bottom {
  display: block;
  margin: 35px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border: 1px solid #000;
}

.list_slider {
  margin: 35px;
  padding-right: 5px;
  padding-left: 5px;
  border: 1px solid #000;
}

.header_produktnummern {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 5px solid #000;
}

.list-item-table-produktnummern {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px none #000;
}

.columns {
  margin: 5px 30px 30px;
}

.heading-2 {
  border-bottom: 1px solid #000;
  border-radius: 1px;
  text-align: center;
}

.heading-3 {
  border-bottom: 1px solid #000;
  border-radius: 1px;
  text-align: center;
}

.header_tafel_heute {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 5px solid #000;
}

.list-item-table-produktnummern-copy {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid #000;
}

.list-item-table-tafel-heute {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid #000;
}

.auftrag_tafel_wrapper {
  border: 1px solid #000;
  border-radius: 3px;
}

.list_tafel_auftrag_heute {
  display: block;
  margin: 10px 35px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.heading-4 {
  text-align: right;
}

.div-block-5 {
  margin-top: 10px;
}

.heading-5 {
  margin-left: 156px;
}

.section-2 {
  margin-left: 140px;
}

.heading-6 {
  margin-left: 220px;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  padding-bottom: 2px;
  background-color: #020202;
  -o-object-fit: fill;
  object-fit: fill;
}

.div-block-7 {
  width: 220px;
  height: 25px;
  margin: 10px;
  border: 2px solid #000;
  border-radius: 20px;
  text-align: center;
}

.div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 10px 10px 120px;
  padding-top: 0px;
}

.heading-7 {
  margin-top: 0px;
  padding-left: 10px;
}

.image {
  margin-top: 4px;
}

.div-block-9 {
  margin-right: 10px;
  margin-left: 140px;
}

.heading-8 {
  margin-right: 10px;
}

.grid {
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 0px solid #000;
  background-color: hsla(0, 0%, 88%, 0.32);
}

.div-block-10 {
  border: 0px solid #000;
  border-radius: 3px;
}

.text-block-3 {
  background-color: rgba(16, 1, 125, 0.44);
}

.container {
  height: 22px;
  margin-top: 2px;
  margin-bottom: 2px;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid #000;
  border-radius: 15px;
  background-color: #000;
}

.text-block-4 {
  color: #fff;
}

.container-2 {
  height: 2px;
}

.section-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.text-block-5 {
  padding-top: 20px;
  padding-left: 20px;
  background-color: #f1f1f1;
  text-transform: uppercase;
}

.dropdown-toggle {
  padding-top: 5px;
  padding-bottom: 10px;
  border: 1px solid #000;
  background-color: #c1c1c1;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.list-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.list-item-5 {
  padding-right: 30px;
}

.tabellen_-berschrift {
  width: 150px;
  padding-right: 20px;
  padding-left: 20px;
  text-transform: uppercase;
  text-shadow: 1px 0 0 #000;
}

.tabellen_inhalt {
  width: 150px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: left;
}

.div-block-11 {
  margin-bottom: 10px;
}

.tabellen_inhalt_ausklappen {
  width: 150px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: left;
}

.footer-thin-container {
  max-width: 1124px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.footer-thin-wrapper {
  padding-top: 70px;
  padding-bottom: 70px;
  font-family: Montserrat, sans-serif;
}

.footer-thin-wrapper.footer-thin {
  padding-top: 42px;
  padding-bottom: 42px;
  background-color: #2b2c2e;
  color: #e4e8eb;
}

.footer-thin-wrapper.footer-thin.copyright {
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #000;
  color: #939596;
  font-size: 14px;
}

.copyright-text {
  text-align: center;
}

.div-block-12 {
  margin-left: 10px;
  border-right: 3px solid #000;
  border-left: 1px solid #000;
}

.div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 10px;
  padding-left: 0px;
}

.image-2 {
  width: 18px;
  height: 20px;
  padding-right: 0px;
  padding-left: 0px;
}

.div-block-14 {
  border-top: 1px solid #000;
}

.dropdown {
  margin: 11px 11px 11px 10px;
}

.submit-button {
  background-color: #09375f;
  text-align: left;
}

.div-block-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 0px;
  padding-left: 0px;
  border: 1px solid #000;
}

.div-block-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
}

.text-block-3-lager {
  overflow: hidden;
  background-color: rgba(137, 141, 63, 0.44);
}

.text-block-3-abgebrochen {
  background-color: rgba(122, 39, 39, 0.46);
}

.masterliste_order_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.div-ueberschrift {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.pb__tabellenuebersicht_tabellenueberschrift_text {
  display: block;
  width: 150px;
  height: auto;
  margin-right: 0px;
  margin-left: 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  white-space: normal;
  -o-object-fit: fill;
  object-fit: fill;
}

._07_tabelle_ueberschrift_div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

._07_tabelle_inhalt_div {
  width: 150px;
  padding-right: 20px;
  padding-left: 20px;
  font-family: "Open Sans", sans-serif;
}

.container-3 {
  position: static;
  border: 1px solid #000;
}

.text-block-6 {
  border-style: none solid;
  border-width: 1px;
  border-color: #000;
}

.div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.section-copy {
  position: absolute;
  left: 21%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 13px;
  padding-top: 361px;
  padding-bottom: 20px;
}

.navbar-dark-3 {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #000;
  font-family: Montserrat, sans-serif;
}

.text-block-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: lowercase;
}

.nav-link-light-2 {
  padding-right: 30px;
  padding-left: 30px;
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.nav-link-light-2:hover {
  color: #fff;
}

.layout-container {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  font-family: Montserrat, sans-serif;
}

.section-heading-wrap {
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
}

.uppercase-label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.uppercase-label.cc-light {
  opacity: 0.6;
}

.about-services-heading {
  color: #000;
}

.our-services-grid {
  margin-bottom: 120px;
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  -ms-grid-rows: auto 60px auto;
  grid-template-areas:
    ". . ."
    ". . .";
  -ms-grid-columns: 1fr 80px 1fr 80px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.service-icon {
  margin-bottom: 30px;
}

.paragraph-bigger {
  margin-bottom: 10px;
  opacity: 1;
  color: #242424;
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
}

.paragraph-light {
  opacity: 0.6;
  color: #242424;
}

.plans-and-pricing-section {
  padding-top: 70px;
  padding-bottom: 70px;
  font-family: Montserrat, sans-serif;
}

.plans-and-pricing-section.plans-and-pricing-section-background {
  background-color: #efefef;
  color: #000;
}

.plans-and-pricing-container {
  max-width: 1124px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.pricing-heading-2 {
  margin-bottom: 60px;
  font-weight: 400;
  text-align: center;
}

.section-subtitle {
  margin-bottom: 68px;
  color: #747474;
  font-size: 19px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
}

.subheading-bold-text {
  font-weight: 500;
}

.plans-column {
  padding-right: 8px;
  padding-left: 8px;
}

.plan-wrapper {
  min-height: 300px;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  color: #2b2b2b;
  text-align: center;
}

.plan-heading {
  margin-top: 0px;
  margin-bottom: 16px;
  font-family: Montserrat, sans-serif;
  color: #747474;
  font-weight: 400;
}

.price-point {
  margin-bottom: 10px;
  font-size: 14px;
}

.price-accent {
  color: #000;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
}

.plan-divider {
  width: 100%;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: #c7c5c5;
  color: transparent;
}

.additional-feature {
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 18px;
}

.additional-feature.inactive-text {
  color: #c7c5c5;
  text-decoration: line-through;
}

.get-started-button {
  display: inline-block;
  margin-right: 14px;
  padding: 13px 23px;
  border-radius: 3px;
  background-color: #000;
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: background-color 300ms ease, box-shadow 300ms ease,
    color 300ms ease;
  transition: background-color 300ms ease, box-shadow 300ms ease,
    color 300ms ease;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.get-started-button:hover {
  background-color: #489fd9;
}

.get-started-button.in-pricing {
  position: relative;
  display: block;
  margin-top: 24px;
  margin-right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px;
  background-color: #000;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.get-started-button.in-pricing:hover {
  background-color: #242424;
}

.additional-contact {
  margin-top: 40px;
  color: #747474;
  font-weight: 500;
  text-align: center;
}

.page-link {
  border-bottom: 1px solid transparent;
  -webkit-transition: color 300ms ease, border 300ms ease;
  transition: color 300ms ease, border 300ms ease;
  color: #000;
  text-decoration: underline;
}

.page-link:hover {
  border-bottom-color: #489fd9;
}

.subscribe-content-wrapper {
  position: relative;
  display: block;
  width: 940px;
  margin: 40px auto;
  font-family: Montserrat, sans-serif;
}

.contact-form-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 60px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: solid;
  border-width: 0px;
  border-color: rgba(210, 210, 213, 0.5);
  background-color: #fff;
}

.form-wrap {
  width: 65%;
  margin-right: 60px;
  padding-right: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-right: 1px solid rgba(210, 210, 213, 0.3);
}

.subscribe-form-label {
  position: static;
  display: block;
  opacity: 0.6;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.subscribe-form-label.cc-contact-form-label {
  margin-bottom: 10px;
  color: #000;
}

.contact-form-headline {
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 20px;
}

.message-form {
  margin-bottom: 0px;
}

.field-label {
  color: #747474;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.text-field {
  display: block;
  height: 54px;
  margin-bottom: 20px;
  padding: 18px 24px;
  border: 1px solid #e5e5e7;
  border-radius: 0px;
  -webkit-transition: border 400ms ease, color 400ms ease;
  transition: border 400ms ease, color 400ms ease;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  cursor: pointer;
}

.text-field:hover {
  border-color: rgba(29, 31, 46, 0.5);
}

.text-field:active {
  border-color: #1d1f2e;
}

.text-field:focus {
  border-color: #1d1f2e;
}

.text-field::-webkit-input-placeholder {
  color: rgba(29, 31, 46, 0.3);
}

.text-field:-ms-input-placeholder {
  color: rgba(29, 31, 46, 0.3);
}

.text-field::-ms-input-placeholder {
  color: rgba(29, 31, 46, 0.3);
}

.text-field::placeholder {
  color: rgba(29, 31, 46, 0.3);
}

.text-field.cc-textarea {
  height: 100px;
}

.dark-button {
  position: relative;
  display: inline-block;
  padding: 18px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0px;
  background-color: #000;
  box-shadow: none;
  -webkit-transition: background-color 400ms ease, opacity 400ms ease,
    box-shadow 400ms ease;
  transition: background-color 400ms ease, opacity 400ms ease,
    box-shadow 400ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.dark-button:hover {
  background-color: #2f3247;
  box-shadow: 0 3px 8px 0 rgba(29, 31, 46, 0.15);
  opacity: 0.96;
  color: #fff;
}

.contact-form-success-message {
  border: 1px solid #ececed;
  background-color: #fafafa;
}

.contact-form-success-icon {
  margin-top: 0px;
  margin-bottom: 10px;
}

.error-message {
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #a25f4b;
  color: #fff;
}

.contact-form-info {
  margin-bottom: 40px;
}

.office-headline {
  margin-bottom: 10px;
  color: #000;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.contact-form-paragraph {
  color: #747474;
}

.contact-form-link {
  color: #000;
  font-size: 18px;
  line-height: 30px;
}

.contact-form-link:hover {
  color: #747474;
}

.footer-wrapper {
  padding-bottom: 60px;
  font-family: Montserrat, sans-serif;
}

.wrap {
  position: relative;
  display: block;
  width: 940px;
  margin: 40px auto;
}

.footer {
  width: 940px;
  margin-top: 100px;
  margin-bottom: 80px;
  justify-items: start;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-areas: "Area";
  -ms-grid-columns: 6fr 20px 3.25fr 20px 3.25fr 20px 4fr;
  grid-template-columns: 6fr 3.25fr 3.25fr 4fr;
  -ms-grid-rows: auto 20px auto;
  grid-template-rows: auto auto;
}

.company-text {
  color: #747474;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}

.small-paragraph {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  color: #333;
  font-size: 14px;
  line-height: 22px;
}

.small-paragraph.cc-copyright-footer {
  margin-bottom: 10px;
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  color: rgba(29, 31, 46, 0.3);
}

.small-paragraph.cc-copyright-footer:hover {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
  color: #747474;
}

.label {
  position: static;
  display: block;
  opacity: 0.6;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.label.cc-footer-section-headline {
  color: #747474;
}

.footer-grid-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  align-self: end;
}

.footer-link {
  margin-bottom: 10px;
  color: rgba(29, 31, 46, 0.7);
  font-size: 14px;
  line-height: 22px;
}

.footer-link:hover {
  color: #242424;
}

.footer-email-link {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #000;
  font-size: 14px;
  line-height: 36px;
  font-weight: 400;
}

.footer-email-link:hover {
  color: #242424;
}

.footer-thin-container-2 {
  max-width: 1124px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.footer-thin-wrapper-2 {
  padding-top: 70px;
  padding-bottom: 70px;
  font-family: Montserrat, sans-serif;
}

.footer-thin-wrapper-2.footer-thin {
  padding-top: 42px;
  padding-bottom: 42px;
  background-color: #2b2c2e;
  color: #e4e8eb;
}

.footer-thin-wrapper-2.footer-thin.copyright {
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #000;
  color: #939596;
  font-size: 14px;
}

.div-block-20 {
  overflow: scroll;
}

._05_monatssicht_header {
  font-weight: 700;
  text-transform: uppercase;
}

.grid-copy {
  -ms-grid-columns: 1fr 1fr 0.75fr 0.5fr 0.5fr 0.5fr 1fr 1fr 0.75fr 0.75fr;
  grid-template-columns: 1fr 1fr 0.75fr 0.5fr 0.5fr 0.5fr 1fr 1fr 0.75fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 0px solid #000;
  background-color: hsla(0, 0%, 88%, 0.32);
}

.blaues-kreuz {
  background-color: rgba(12, 22, 215, 0.84);
  color: #fff;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}

.rotes-kreuz {
  background-color: rgba(215, 12, 12, 0.84);
  color: #fff;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}

.list-item-6 {
  background-color: #d6d6d6;
  text-align: center;
  list-style-type: none;
}

.paragraph {
  height: auto;
}

.div-block-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
}

.container-4 {
  height: 20px;
  background-color: #000;
}

.body {
  height: 30px;
}

.text-block-8 {
  text-align: center;
}

.image-3 {
  margin-left: 57px;
  padding-left: 0px;
  text-align: center;
}

.image-4 {
  margin-left: 57px;
}

.div-block-18-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #bcd2db;
}

.div-block-18-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #bcd2db;
}

.div-block-18-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.div-block-18-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #bcd2db;
}

.checkbox-field {
  margin-bottom: 0px;
  padding-left: 0px;
}

.checkbox-label {
  padding-bottom: 0px;
}

.form_block_checkbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-6-copy {
  border: 1px none #000;
}

.select-field {
  margin-bottom: 0px;
}

.section-4 {
  display: block;
}

.section-5 {
  display: block;
}

.body-2 {
  text-align: center;
}

.form-block-4 {
  position: static;
  top: 180px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.erfolg {
  position: static;
  left: 0px;
  top: 0px;
  display: none;
  width: auto;
  height: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #d4edda;
  color: #155724;
  font-weight: 700;
  text-align: center;
}

.email_vergeben {
  position: static;
  left: 0px;
  top: 0px;
  display: none;
  width: auto;
  height: auto;
  margin-top: 40px;
  margin-bottom: 0px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #f8d7da;
  color: #881c59;
  font-weight: 700;
  text-align: center;
}

.anbieter_registrierung2login {
  display: none;
  width: 400px;
  margin-top: 40px;
  margin-right: 0px;
  margin-bottom: 40px;
  float: left;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #bebebe;
  background-color: #bedeb5;
  color: #000;
  text-align: center;
}

.kf_reg_form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.anbieter_registrierung_submit {
  display: block;
  width: 400px;
  margin-top: 40px;
  margin-right: 0px;
  margin-bottom: 10px;
  float: left;
  border-style: solid;
  border-width: 1px;
  border-color: #bebebe;
  background-color: #dfdfdf;
  color: #000;
  text-align: center;
}

.reg2login {
  text-align: center;
}

.heading-24 {
  position: static;
  margin-top: 60px;
  margin-bottom: 40px;
  color: #000;
  font-size: 48px;
  font-weight: 300;
}

.text-block-86 {
  color: #881c59;
  font-size: 16px;
  text-align: center;
}

.reset2login {
  width: auto;
  margin-bottom: 10px;
  text-align: center;
}

.kf_kennwort_reset_hinweis {
  display: block;
  width: 500px;
  margin-bottom: 40px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #eff3ff;
  color: #908191;
  font-weight: 500;
  text-align: center;
}

.anbieter_login_submit {
  display: block;
  width: 500px;
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 20px;
  float: left;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #bebebe;
  background-color: #495247;
  color: #fff;
  text-align: center;
}

.login_sperrung_div {
  display: none;
  margin-bottom: 40px;
  padding: 10px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 5px;
  background-color: #f8d7da;
}

.text-block-86-copy {
  margin-bottom: 20px;
  color: #881c59;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.container-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 500px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.kf_login_form {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 500px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.login_misserfolg {
  display: none;
  width: 500px;
  margin-bottom: 40px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #f8d7da;
  color: #881c59;
  font-weight: 500;
}

.heading-25 {
  margin-top: 60px;
  margin-bottom: 40px;
  color: #000;
  font-size: 48px;
}

.kf_kennwort_reset_form {
  position: static;
  display: none;
  width: 500px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.login_sperrung_more {
  display: block;
  width: auto;
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 0px;
  float: left;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #bebebe;
  background-color: #c06c9c;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

.login2reg {
  margin-bottom: 10px;
}

.kf_loading {
  display: none;
}

.login_sperrung_2login {
  display: block;
  width: auto;
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 20px;
  float: left;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #bebebe;
  background-color: #eff3ff;
  color: #908191;
  font-weight: 700;
  text-align: center;
}

.kf_login_f {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.kf_kennwort_reset_f {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.anbieter_kennwort_reset_submit {
  display: block;
  width: 500px;
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 20px;
  float: left;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #bebebe;
  background-color: #bedeb5;
  color: #000;
  text-align: center;
}

.container-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.anbieter_registrierung_submit-2 {
  display: block;
  width: 400px;
  margin-top: 40px;
  margin-right: 0px;
  margin-bottom: 10px;
  float: left;
  border-style: solid;
  border-width: 1px;
  border-color: #bebebe;
  background-color: #495247;
  color: #fff;
  text-align: center;
}

.navbar-dark-nav-button-_-navigationpage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 30px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 0px;
  background-color: #000;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.navbar-dark-nav-button-_-navigationpage:hover {
  background-color: hsla(0, 0%, 100%, 0.8);
}

.container-17 {
  padding-top: 40px;
}

.home_body {
  background-color: #1b1b1b;
}

.grid-copy-2 {
  -ms-grid-columns: 1fr 1fr 0.75fr 0.5fr;
  grid-template-columns: 1fr 1fr 0.75fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 0px solid #000;
  background-color: hsla(0, 0%, 88%, 0.32);
}

._05_monatssicht_header-copy {
  font-weight: 700;
  text-transform: uppercase;
}

._05_monatssicht_header-copy-2 {
  font-weight: 700;
  text-transform: uppercase;
}

.grid-copy-copy2 {
  -ms-grid-columns: 1fr 1fr 0.75fr 0.75fr;
  grid-template-columns: 1fr 1fr 0.75fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 0px solid #000;
  background-color: hsla(0, 0%, 88%, 0.32);
}

.lcs_body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.container-18 {
  padding-top: 100px;
}

.grid-2 {
  width: 800px;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.lcs_hs_button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 150px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 #000;
  text-decoration: none;
}

.lcs_hs_button.inaktiv {
  background-color: #44546a;
  opacity: 0.12;
}

.lcs_hs_button.rot {
  background-color: #dfb36f;
}

.lcs_hs_button.hidden {
  display: none;
}

.text-block-87 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.container-19 {
  position: relative;
  z-index: 2;
  padding-top: 100px;
}

.lcs_footer {
  position: relative;
  z-index: 2;
  height: auto;
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #44546a;
}

.lcs_footer_headingdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-5 {
  width: 75px;
  margin-right: 20px;
}

.lcs_footer_heading {
  color: #fff;
  font-size: 24px;
  line-height: 24px;
}

.lcs_footer_kategorie_heading {
  color: #fff;
  font-weight: 700;
}

.lcs_footer_kategorie_content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_footer_link {
  color: #fff;
}

.lcs_footer_link.w--current {
  margin-right: 0px;
}

.lcs_content {
  position: relative;
  z-index: 2;
  overflow: visible;
  margin-bottom: 300px;
  padding-top: 60px;
}

.lcs_headingdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 900px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_back_pic {
  width: 30px;
  margin-right: 0px;
}

.lcs_h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  font-weight: 400;
}

.lcs_addsection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 grey;
}

.lcs_button.rightmargin {
  margin-right: 20px;
}

.lcs_buttonpic {
  width: 20px;
  margin-right: 10px;
}

.text-block-88 {
  color: #fff;
}

.lcs_buttontext {
  color: #fff;
}

.lcs_produkte_produkt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 grey;
  color: #fff;
  font-size: 28px;
}

.lcs_produkte_listenitem {
  display: none;
  margin-bottom: 20px;
}

.lcs_produkte_baugruppe {
  display: none;
  margin-bottom: 5px;
  margin-left: 100px;
  padding: 10px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 grey;
  color: #fff;
  font-size: 20px;
}

.lcs_produkte_produktname {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  line-height: 1;
}

.lcs_produkte_icon {
  display: block;
  width: 20px;
}

.lcs_produkte_maske {
  display: none;
  padding-top: 20px;
  padding-bottom: 10px;
}

.lcs_h2 {
  background-color: transparent;
  color: #fff;
  font-weight: 400;
}

.form-block-5 {
  margin-top: 20px;
}

.lcs_inputlabel {
  margin-top: 20px;
  color: #fff;
  font-weight: 500;
}

.lcs_inputlabel.hidden {
  display: none;
}

.lcs_inputfield {
  margin-right: 0px;
  margin-bottom: 0px;
}

.lcs_inputfield.nomarginbot {
  margin-bottom: 0px;
}

.lcs_inputfield.nomarginbot.marginright {
  margin-right: 20px;
}

.lcs_submitdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_komponenten_listenitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 grey;
}

.lcs_komponenten_komponentenname {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  line-height: 1;
}

.lcs_komponenten_id {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 20px;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  font-size: 28px;
  line-height: 1;
}

.div-block-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_komponenten_maske {
  display: none;
  padding-top: 20px;
  padding-bottom: 10px;
}

.lcs_komponenten_formblock {
  margin-top: 20px;
}

.lcs_produkte_baugruppenname {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  line-height: 1;
}

.lcs_produkte_bom {
  width: 20px;
}

.lcs_bom_legende {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-min-content -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content max-content min-content
    max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-min-content -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content max-content min-content max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #44546a;
}

.lcs_bom_legendentext {
  overflow: visible;
  width: 140px;
  padding: 10px 5px;
  color: #fff;
  -o-object-fit: fill;
  object-fit: fill;
}

.lcs_bom_legendentext.bom_komponentenid {
  width: 100px;
  font-size: 16px;
  text-align: left;
}

.lcs_bom_legendentext.bom_bezeichnung {
  width: 200px;
  font-size: 16px;
}

.lcs_bom_legendentext.bom_designatoren {
  width: 170px;
  font-size: 16px;
}

.lcs_bom_legendentext.bom_anzahl {
  width: 150px;
  font-size: 16px;
}

.lcs_bom_legendentext.bom_fitted {
  width: 60px;
  font-size: 16px;
}

.lcs_bom_legendentext.bom_lieferlage {
  width: 90px;
  font-size: 16px;
}

.lcs_bom_legendentext.bom_anwendung {
  width: 120px;
  font-size: 16px;
}

.lcs_bom_legendenph {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_bom_item {
  display: none;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content max-content max-content
    max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #6280a8;
}

.lcs_bom_items {
  background-color: transparent;
}

.image-6 {
  width: 20px;
}

.lcs_bom_itemtext {
  overflow: visible;
  width: 140px;
  padding: 10px 5px;
  color: #fff;
  -o-object-fit: fill;
  object-fit: fill;
}

.lcs_bom_itemtext.bom_komponentenid {
  display: block;
  width: 100px;
  text-align: left;
  -o-object-fit: fill;
  object-fit: fill;
}

.lcs_bom_itemtext.bom_bezeichnung {
  display: inline-block;
  width: 200px;
}

.lcs_bom_itemtext.bom_designatoren {
  display: inline-block;
  width: 170px;
  float: none;
}

.lcs_bom_itemtext.bom_anzahl {
  display: inline-block;
  width: 150px;
}

.lcs_bom_itemtext.bom_fitted {
  display: inline-block;
  width: 60px;
}

.lcs_bom_itemtext.bom_lieferlage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.lcs_bom_itemtext.bom_anwendung {
  display: inline-block;
  width: 120px;
}

.lcs_bom_edit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  padding-top: 10px;
  padding-right: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.lcs_bom_maske {
  display: none;
  padding-top: 20px;
  padding-bottom: 10px;
}

.lcs_bom_formblock {
  margin-top: 20px;
}

.div-block-23 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_bom_maske_kg {
  display: none;
  width: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  padding: 5px;
  border-radius: 5em;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 grey;
  color: #fff;
}

.lcs_select.nomarginbot {
  margin-bottom: 0px;
}

.lcs_select.nomarginbot.width50 {
  width: 50%;
}

.lcs_produkte_maske_baugruppe_input {
  margin-bottom: 10px;
}

.lcs_produkte_maske_baugruppe_input.nomarginbot {
  margin-bottom: 0px;
}

.lcs_produkte_maske_baugruppe_input.nomarginbot.marginright {
  margin-right: 20px;
}

.lcs_feedbackdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_feedbacktext {
  display: none;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #f8d7da;
  color: #881c59;
  font-weight: 500;
}

.lcs_produkte_maske_baugruppe_item {
  display: none;
  margin-bottom: 5px;
  margin-left: 0px;
  padding: 10px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 grey;
  color: #fff;
  font-size: 20px;
}

.lcs_produkte_maske_baugruppe_item_name {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  line-height: 1;
}

.lcs_produkte_maske_baugruppe_item_icon {
  width: 20px;
  margin-right: 0px;
}

.lcs_produkte_maske_baugruppe_item_icon.marginright {
  margin-right: 10px;
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_produkte_maske_baugruppe_editbuttons {
  display: none;
  margin-left: 10px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_icondiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_icondiv.marginright {
  margin-right: 10px;
}

.lcs_icondiv.marginleft {
  margin-left: 10px;
}

.lcs_back {
  margin-right: 40px;
}

.lcs_back.fixed {
  position: fixed;
  left: 40px;
  top: 100px;
  right: auto;
  bottom: auto;
  z-index: 2;
}

.lcs_background_gradient {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  background-image: linear-gradient(225deg, #000, #262626 50%, #404040);
}

.lcs_komponenten_legende {
  display: none;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #44546a;
}

.lcs_komponenten_legendentext {
  overflow: visible;
  width: 140px;
  padding: 10px 5px;
  color: #fff;
  -o-object-fit: fill;
  object-fit: fill;
}

.lcs_komponenten_legendentext.bom_komponentenid {
  width: 100px;
  font-size: 16px;
  text-align: left;
}

.lcs_komponenten_legendentext.bom_bezeichnung {
  width: 200px;
  font-size: 16px;
}

.lcs_komponenten_legendentext.bom_designatoren {
  width: 170px;
  font-size: 16px;
}

.lcs_komponenten_legendentext.bom_anzahl {
  width: 150px;
  font-size: 16px;
}

.lcs_komponenten_legendentext.bom_fitted {
  width: 60px;
  font-size: 16px;
}

.lcs_komponenten_legendentext.bom_lieferlage {
  width: 90px;
  font-size: 16px;
}

.lcs_komponenten_legendentext.bom_anwendung {
  width: 120px;
  font-size: 16px;
}

.lcs_komponenten_legendentext.komponenten_id {
  width: 150px;
}

.lcs_komponenten_legendentext.komponenten_bezeichnung {
  width: 650px;
}

.lcs_komponenten_legendentext.lt_art {
  width: 700px;
}

.lcs_komponenten_legendenph {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_komponenten_items {
  background-color: transparent;
}

.lcs_komponenten_item {
  display: none;
  padding: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content 1fr 1fr;
  -ms-grid-columns: max-content max-content 1fr 1fr;
  grid-template-columns: -webkit-max-content -webkit-max-content 1fr 1fr;
  grid-template-columns: max-content max-content 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: transparent;
}

.lcs_komponenten_itemtext {
  overflow: visible;
  width: 140px;
  padding: 10px 5px;
  color: #fff;
  -o-object-fit: fill;
  object-fit: fill;
}

.lcs_komponenten_itemtext.bom_komponentenid {
  width: 100px;
  text-align: left;
}

.lcs_komponenten_itemtext.bom_bezeichnung {
  width: 200px;
}

.lcs_komponenten_itemtext.bom_designatoren {
  width: 170px;
}

.lcs_komponenten_itemtext.bom_anzahl {
  width: 150px;
}

.lcs_komponenten_itemtext.bom_fitted {
  width: 60px;
}

.lcs_komponenten_itemtext.bom_lieferlage {
  width: 90px;
}

.lcs_komponenten_itemtext.bom_anwendung {
  width: 120px;
}

.lcs_komponenten_itemtext.komponenten_id {
  width: 150px;
}

.lcs_komponenten_itemtext.komponenten_bezeichnung {
  width: auto;
  margin-right: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5em;
  background-color: #44546a;
}

.lcs_komponenten_icon {
  display: block;
  width: 20px;
  height: 20px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_komponenten_bezeichnungsdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 650px;
  height: auto;
  max-height: none;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  -ms-grid-columns: -webkit-max-content;
  -ms-grid-columns: max-content;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lcs_komponenten_komponentengruppe {
  display: none;
  padding: 5px 10px;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.lcs_komponenten_maske_komponentengruppen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lcs_komponenten_maske_komponentengruppe {
  display: none;
  margin-right: 10px;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.lcs_komponenten_maske_vorschlagsdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lcs_komponenten_maske_komponentengruppe_text {
  display: block;
  margin-right: 0px;
  padding: 5px 10px;
  border-radius: 0em;
  background-color: transparent;
  color: #fff;
}

.lcs_komponenten_maske_komponentengruppe_icon {
  display: none;
  width: 15px;
}

.lcs_komponenten_maske_vorschlag {
  display: none;
  margin-right: 10px;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.lcs_komponenten_maske_vorschlag_text {
  display: block;
  margin-right: 0px;
  padding: 5px 10px;
  border-radius: 0em;
  background-color: transparent;
  color: #fff;
}

.lcs_komponenten_maske_vorschlag_icon {
  display: none;
  width: 15px;
}

.lcs_bom_maske_kgdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lcs_bom_kgs_div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  padding: 10px 5px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.lcs_bom_kg {
  display: none;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.lcs_bom_lieferlage {
  width: 20px;
  height: 20px;
  border-radius: 5em;
}

.lcs_bom_lieferlage.rot {
  background-color: red;
  opacity: 1;
}

.lcs_bom_lieferlage.gelb {
  background-color: #ff0;
}

.lcs_bom_lieferlage.gruen {
  background-color: #0f0;
}

.div-block-26 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  padding: 5px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-radius: 5em;
  background-color: #44546a;
}

.lcs_lager_liste {
  min-height: 50px;
  padding: 10px;
  border-radius: 0px;
}

.lcs_lager_listenitem {
  display: none;
  margin-bottom: 20px;
}

.lcs_lager_lagerort {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding: 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 grey;
  color: #fff;
  font-size: 28px;
}

.lcs_lager_lagerortname {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  font-size: 24px;
  line-height: 1;
}

.lcs_lager_icon {
  display: block;
  width: 20px;
  margin-right: 0px;
  margin-left: 0px;
}

.lcs_lager_ladungstraeger {
  display: none;
  margin-bottom: 5px;
  margin-left: 100px;
  padding: 10px 15px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 grey;
  color: #fff;
  font-size: 20px;
}

.lcs_lager_ladungstraegername {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  line-height: 1;
}

.lcs_lager_maske {
  display: none;
  padding-top: 20px;
  padding-bottom: 10px;
}

.lager_formblock {
  margin-top: 20px;
  padding-bottom: 10px;
}

.lcs_admin_section {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #bebebe;
}

.lcs_admin_container {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
}

._100vh {
  width: 250px;
  height: auto;
}

.navbar-2 {
  height: auto;
}

.lcs_admin_links._100vh {
  overflow: auto;
  padding: 40px 10px;
  background-color: #44546a;
}

.lcs_admin_rechts {
  width: 750px;
}

.lcs_admin_rechts._100vh {
  overflow: auto;
  padding: 40px 10px;
  background-color: #6280a8;
}

.lcs_admin_h1 {
  padding-left: 5px;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
}

.lcs_admin_menuentry {
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 10px;
  color: #bebebe;
}

.lcs_admin_content {
  display: none;
}

.lcs_admin_h2 {
  padding-left: 0px;
  color: #fff;
  font-size: 18px;
  line-height: 20px;
}

.lcs_admin_h3 {
  padding-left: 0px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}

.lcs_admin_infoblock {
  background-color: transparent;
  color: #fff;
}

.lcs_admin_kg_items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lcs_admin_kg_item {
  display: none;
  margin-right: 0px;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #44546a;
  color: #fff;
}

.lcs_admin_kg_item.angefragt {
  background-color: #dfb36f;
}

.lcs_admin_kg_text {
  display: block;
  margin-right: 0px;
  padding: 5px 10px;
  border-radius: 0em;
  background-color: transparent;
  color: #fff;
  line-height: 14px;
}

.lcs_admin_kg_icon {
  display: none;
  width: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

.lcs_admin_kg_anfragen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lcs_admin_kg_anfrage {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  padding: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5em;
  background-color: #dfb36f;
  color: #fff;
}

.lcs_admin_kg_input {
  margin-bottom: 0px;
}

.lcs_admin_kg_editform {
  display: none;
  margin-bottom: 0px;
  margin-left: 10px;
}

.lcs_admin_kg_editicon {
  display: block;
  width: 25px;
  margin-right: 5px;
  margin-left: 5px;
}

.lcs_admin_kg_innerform {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_admin_kg_warning {
  display: none;
  margin-right: 0px;
  margin-left: 0px;
  padding: 5px 5px 5px 10px;
  border-radius: 10px;
  background-color: transparent;
  color: red;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  text-align: left;
}

.lcs_hs_back {
  position: absolute;
  left: -50px;
  top: 100px;
  right: auto;
  bottom: auto;
  display: none;
  margin-right: 40px;
}

.lcs_hs_back.fixed {
  position: fixed;
  left: 40px;
  top: 100px;
  right: auto;
  bottom: auto;
  z-index: 2;
}

.lcs_lt_legende {
  display: none;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #44546a;
}

.lcs_lt_legendentext {
  overflow: visible;
  width: 140px;
  padding: 10px 5px;
  color: #fff;
  -o-object-fit: fill;
  object-fit: fill;
}

.lcs_lt_legendentext.bom_komponentenid {
  width: 100px;
  font-size: 16px;
  text-align: left;
}

.lcs_lt_legendentext.bom_bezeichnung {
  width: 200px;
  font-size: 16px;
}

.lcs_lt_legendentext.bom_designatoren {
  width: 170px;
  font-size: 16px;
}

.lcs_lt_legendentext.bom_anzahl {
  width: 150px;
  font-size: 16px;
}

.lcs_lt_legendentext.bom_fitted {
  width: 60px;
  font-size: 16px;
}

.lcs_lt_legendentext.bom_lieferlage {
  width: 90px;
  font-size: 16px;
}

.lcs_lt_legendentext.bom_anwendung {
  width: 120px;
  font-size: 16px;
}

.lcs_lt_legendentext.komponenten_id {
  width: 150px;
}

.lcs_lt_legendentext.komponenten_bezeichnung {
  width: 700px;
}

.lcs_lt_legendentext.lt_id {
  width: 150px;
}

.lcs_lt_legendentext.lt_art {
  width: 350px;
}

.lcs_lt_legendentext.lt_lagerort {
  width: 300px;
}

.lcs_lt_legendenph {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_lt_items {
  background-color: transparent;
}

.lcs_lt_item {
  display: none;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content
    1fr 1fr;
  -ms-grid-columns: max-content max-content max-content 1fr 1fr;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content 1fr 1fr;
  grid-template-columns: max-content max-content max-content 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: transparent;
}

.lcs_lt_itemtext {
  overflow: visible;
  width: 140px;
  padding: 10px 5px;
  color: #fff;
  -o-object-fit: fill;
  object-fit: fill;
}

.lcs_lt_itemtext.bom_komponentenid {
  width: 100px;
  text-align: left;
}

.lcs_lt_itemtext.bom_bezeichnung {
  width: 200px;
}

.lcs_lt_itemtext.bom_designatoren {
  width: 170px;
}

.lcs_lt_itemtext.bom_anzahl {
  width: 150px;
}

.lcs_lt_itemtext.bom_fitted {
  width: 60px;
}

.lcs_lt_itemtext.bom_lieferlage {
  width: 90px;
}

.lcs_lt_itemtext.bom_anwendung {
  width: 120px;
}

.lcs_lt_itemtext.komponenten_id {
  width: 150px;
}

.lcs_lt_itemtext.komponenten_bezeichnung {
  width: auto;
  margin-right: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5em;
  background-color: #44546a;
}

.lcs_lt_itemtext.lt_id {
  width: 150px;
}

.lcs_lt_itemtext.lt_lagerort {
  width: 300px;
}

.lcs_lt_artdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 350px;
  height: auto;
  max-height: none;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  -ms-grid-columns: -webkit-max-content;
  -ms-grid-columns: max-content;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lcs_lt_art {
  display: none;
  padding: 5px 10px;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.lcs_lt_edit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_lt_maske {
  display: none;
  padding-top: 20px;
  padding-bottom: 10px;
}

.lcs_lt_formblock {
  margin-top: 20px;
}

.lcs_lt_maske_ltarten {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lcs_lt_maske_ltart {
  display: none;
  margin-right: 10px;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.lcs_lt_maske_ltart_text {
  display: block;
  margin-right: 0px;
  padding: 5px 10px;
  border-radius: 0em;
  background-color: transparent;
  color: #fff;
}

.lcs_lt_maske_ltart_icon {
  display: none;
  width: 15px;
}

.lcs_lt_maske_vorschlagsdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lcs_lt_maske_vorschlag {
  display: none;
  margin-right: 10px;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.lcs_lt_maske_vorschlag_text {
  display: block;
  margin-right: 0px;
  padding: 5px 10px;
  border-radius: 0em;
  background-color: transparent;
  color: #fff;
}

.lcs_lt_maske_vorschlag_icon {
  display: none;
  width: 15px;
}

.lcs_lager_klapptrigger {
  width: 50px;
  margin-top: 20px;
  padding: 5px;
  border-radius: 10px;
  background-color: #6280a8;
  box-shadow: 1px 1px 3px 0 #333;
  font-size: 18px;
  text-align: center;
}

.div-block-28 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.lcs_lager_ladungstraeger_arten {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_lager_ladungstraeger_art {
  display: none;
  width: auto;
  height: 30px;
  margin-top: 0px;
  margin-right: 10px;
  padding: 5px;
  border-radius: 10px;
  background-color: #6280a8;
  font-size: 18px;
  text-align: center;
}

.lcs_lager_maske_ltliste {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 50px;
  padding: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 0px solid #fff;
  border-bottom: 0px solid #000;
  border-radius: 10px;
  background-color: #8babd8;
}

.lcs_lager_maske_ltitem {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lcs_lager_maske_lt_id {
  width: 150px;
}

.lcs_lager_maske_lt_arten {
  width: 700px;
}

.lcs_lager_maske_lt_delete {
  width: 50px;
}

.lager_maske_ltliste_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #6280a8;
}

.text-block-89 {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 0px solid #fff;
  color: #fff;
  font-weight: 500;
}

.lager_maske_ltliste_itemtext {
  overflow: visible;
  width: 140px;
  padding: 10px 5px;
  color: #fff;
  -o-object-fit: fill;
  object-fit: fill;
}

.lager_maske_ltliste_itemtext.bom_komponentenid {
  width: 100px;
  text-align: left;
}

.lager_maske_ltliste_itemtext.bom_bezeichnung {
  width: 200px;
}

.lager_maske_ltliste_itemtext.bom_designatoren {
  width: 170px;
}

.lager_maske_ltliste_itemtext.bom_anzahl {
  width: 150px;
}

.lager_maske_ltliste_itemtext.bom_fitted {
  width: 60px;
}

.lager_maske_ltliste_itemtext.bom_lieferlage {
  width: 90px;
}

.lager_maske_ltliste_itemtext.bom_anwendung {
  width: 120px;
}

.lager_maske_ltliste_itemtext.lt_id {
  width: 150px;
  padding-top: 0px;
}

.lager_maske_ltliste_itemtext.komponenten_bezeichnung {
  width: auto;
  margin-right: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5em;
  background-color: #44546a;
}

.lager_maske_ltliste_artendiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 700px;
  height: auto;
  max-height: none;
  padding-top: 5px;
  padding-bottom: 5px;
  justify-items: stretch;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  grid-auto-flow: column;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  -ms-grid-columns: -webkit-max-content;
  -ms-grid-columns: max-content;
  grid-template-columns: -webkit-max-content;
  grid-template-columns: max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lager_maske_ltliste_art {
  display: none;
  padding: 5px 10px;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.lager_maske_ltliste_delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lager_maske_lt_select {
  width: 400px;
  margin-right: 20px;
  margin-bottom: 0px;
}

.grid-3 {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.lager_maske_vorschlagsliste {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 50px;
  padding: 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
}

.lager_maske_lt_item {
  position: relative;
  z-index: 2;
  display: none;
  width: 250px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 5px;
  background-color: #6280a8;
  box-shadow: 1px 1px 3px 0 #000;
}

.lager_maske_lt_name {
  width: auto;
  margin-bottom: 0px;
  color: #fff;
  text-align: center;
}

.lager_maske_lt_icon {
  width: 15px;
  margin-top: 0px;
  margin-right: 5px;
  margin-left: 5px;
}

.lager_maske_lt_arten {
  display: none;
  width: 100%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.lager_maske_lt_art {
  display: none;
  margin-right: 5px;
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.div-block-30 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-89-copy {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 0px;
  border-top: 1px solid #fff;
  color: #fff;
  font-weight: 500;
}

.lcs_lager_innerform.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_admin_lta_items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lcs_admin_lta_anfragen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lcs_admin_lta_item {
  display: none;
  margin-right: 0px;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #44546a;
  color: #fff;
}

.lcs_admin_lta_item.angefragt {
  background-color: #dfb36f;
}

.lcs_admin_kg_item-copy {
  display: none;
  margin-right: 0px;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #44546a;
  color: #fff;
}

.lcs_admin_kg_item-copy.angefragt {
  background-color: #dfb36f;
}

.lcs_admin_lta_text {
  display: block;
  margin-right: 0px;
  padding: 5px 10px;
  border-radius: 0em;
  background-color: transparent;
  color: #fff;
  line-height: 14px;
}

.lcs_admin_lta_editform {
  display: none;
  margin-bottom: 0px;
  margin-left: 10px;
}

.lcs_admin_lta_innerform {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_admin_lta_input {
  margin-bottom: 0px;
}

.lcs_admin_lta_editicon {
  display: block;
  width: 25px;
  margin-right: 5px;
  margin-left: 5px;
}

.lcs_admin_kg_editicon-copy {
  display: block;
  width: 25px;
  margin-right: 5px;
  margin-left: 5px;
}

.lcs_admin_lta_icon {
  display: none;
  width: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

.lcs_admin_lta_warning {
  display: none;
  margin-right: 0px;
  margin-left: 0px;
  padding: 5px 5px 5px 10px;
  border-radius: 10px;
  background-color: transparent;
  color: red;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  text-align: left;
}

.lcs_produkte_icondiv {
  margin-left: 10px;
}

.div-block-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_komponenten_icondiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_feedbacksection {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_lager_icondiv {
  margin-left: 10px;
}

.div-block-32 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_lt_icon {
  display: block;
  width: 20px;
  height: 20px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lcs_lt_icondiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.einlagern_formblock {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 0px;
}

.einlagern_innerform {
  display: block;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.einlagern_innerform.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.einalgern_lt_arten {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.einlagern_lt_art {
  display: none;
  padding: 5px 10px;
  border-radius: 5em;
  background-color: #44546a;
  color: #fff;
}

.einlagern_lt_chargen {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 50px;
  margin-top: 10px;
  padding: 5px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 0px solid #fff;
  border-bottom: 0px solid #000;
  border-radius: 10px;
  background-color: #8babd8;
}

.einlagern_lt_charge {
  position: relative;
  z-index: 2;
  display: none;
  width: 300px;
  margin: 5px;
  padding: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 5px;
  background-color: #6280a8;
  box-shadow: 1px 1px 3px 0 #000;
}

.div-block-30-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.einlagern_chargenid {
  width: auto;
  margin-bottom: 0px;
  color: #fff;
  text-align: center;
}

.einlagern_charge_klappen {
  width: 15px;
  margin-top: 0px;
  margin-right: 5px;
  margin-left: 5px;
}

.einlagern_charge_infos {
  display: none;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: #fff;
  list-style-type: none;
}

.list-3 {
  margin-bottom: 0px;
  padding-left: 0px;
  color: #fff;
  list-style-type: none;
}

.list-item-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.einlagern_charge_kgs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.einlagern_charge_kg {
  display: none;
  margin: 5px;
  padding: 5px;
  border-radius: 5em;
  background-color: #44546a;
  font-size: 12px;
}

.einlagern_form_div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.einlagern_select.nomarginbot {
  margin-bottom: 0px;
}

.einlagern_select.nomarginbot.width50 {
  width: 50%;
}

.einlagern_form_kgs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.einlagern_form_kg {
  display: none;
  width: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  padding: 5px;
  border-radius: 5em;
  background-color: #44546a;
  box-shadow: 1px 1px 3px 0 grey;
  color: #fff;
}

.einlagern_inputfield {
  margin-right: 0px;
  margin-bottom: 0px;
}

.einlagern_inputfield.nomarginbot {
  margin-bottom: 0px;
}

.einlagern_inputfield.nomarginbot.marginright {
  margin-right: 20px;
}

.div-block-33 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.einlagern_content {
  display: block;
}

.image-220 {
  position: relative;
}

.ic_heading_hor {
  position: absolute;
  left: auto;
  top: 10px;
  right: auto;
  bottom: auto;
  z-index: 1;
  display: block;
  width: auto;
  height: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px 0.5vw;
  border: 0px solid #fff;
  border-radius: 5em;
  background-color: #2f3a49;
  box-shadow: -1px 0 3px 0 #6b6b6b;
  color: #fff;
  font-size: 22px;
  line-height: 1;
  font-weight: 500;
  text-align: center;
}

.ic_heading_hor.pos5 {
  z-index: 5;
  display: block;
  width: 15vw;
  border-left-width: 0px;
  box-shadow: none;
}

.ic_heading_hor.pos1 {
  position: absolute;
  top: 10px;
  display: block;
  width: 15vw;
  padding: 10px 0vw;
  border-style: solid;
  border-width: 0px;
  box-shadow: none;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
}

.ic_heading_hor.pos3 {
  z-index: 3;
  display: block;
  width: 10vw;
  box-shadow: none;
}

.ic_heading_hor.pos4 {
  z-index: 4;
  display: block;
  width: 12vw;
  border-left-width: 0px;
  box-shadow: none;
}

.ic_heading_hor.pos6 {
  z-index: 6;
  display: block;
  width: 11vw;
  border-left-width: 0px;
  box-shadow: none;
}

.ic_heading_hor.pos7 {
  z-index: 7;
  display: block;
  width: 11vw;
  border-right-width: 0px;
  border-left-width: 0px;
  box-shadow: none;
}

.ic_heading_hor.pos2 {
  z-index: 2;
  display: block;
  width: 10vw;
  padding: 10px 0.5vw;
  box-shadow: none;
}

.ic_container_phasenmodell {
  max-width: 80em;
  padding-bottom: 60px;
  border-bottom: 1px solid #fff;
  opacity: 0;
}

.div-block-467 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  padding-left: 0.5vh;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5em;
  background-color: #44546a;
}

.div-block-398 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.div-block-469 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 420vw;
  padding-bottom: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.ic_container_title {
  max-width: 80em;
  padding-bottom: 60px;
  border-bottom: 1px solid #fff;
  opacity: 1;
}

.bold-text-20 {
  font-weight: 700;
}

.div-block-472 {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.paragraph-43 {
  width: 600px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-left: 5px solid #fff;
  color: #fff;
  font-size: 3em;
  line-height: 50px;
  font-weight: 200;
}

.image-217 {
  width: 50px;
  margin-right: 1vw;
}

.ic_h2 {
  overflow: hidden;
  width: auto;
  height: auto;
  max-width: none;
  margin-top: 0px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 4em;
  line-height: 1.5;
  font-weight: 700;
  text-align: left;
}

.ic_section_title {
  height: auto;
  padding: 25vh 0px 5vh;
}

.text-block-104-copy {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #fff;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
}

.text-block-224 {
  color: #fff;
  font-size: 24px;
}

.container-47-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-92-copy {
  width: 50px;
  height: auto;
  margin-right: 40px;
}

.image-220-mobil {
  position: relative;
  display: none;
}

.div-block-465 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 420vw;
  height: auto;
}

.div-block-470 {
  position: static;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: transparent;
}

.ic_phasen_beschreibung {
  width: 45vw;
  max-width: none;
  color: #fff;
  font-size: 1.5em;
  line-height: 2;
  text-align: justify;
}

.hamburger_line1 {
  position: absolute;
  left: 13px;
  top: 17px;
  width: 24px;
  height: 3px;
  background-color: #1b1b1b;
}

.ic_wsk_step {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
}

.ic_wsk_step.maxw40 {
  max-width: 40vw;
  font-size: 2.5em;
}

.ic_frame {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin-top: 0px;
  padding-top: 70px;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  grid-auto-columns: -webkit-max-content;
  grid-auto-columns: max-content;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  -ms-grid-columns: max-content max-content max-content max-content max-content
    max-content max-content max-content max-content max-content max-content
    max-content max-content max-content max-content max-content max-content
    max-content max-content max-content max-content max-content max-content
    max-content max-content max-content max-content max-content;
  grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content max-content;
  -ms-grid-rows: -webkit-max-content 40vh;
  -ms-grid-rows: max-content 40vh;
  grid-template-rows: -webkit-max-content 40vh;
  grid-template-rows: max-content 40vh;
}

.image-221 {
  width: 50px;
}

.link-block-20 {
  padding: 10px;
  border-radius: 2em;
  background-color: #44546a;
  text-decoration: none;
}

.ic_camera {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.ic_h1 {
  width: 80vw;
  background-color: transparent;
  color: #fff;
  font-size: 5em;
  line-height: 1.1;
  font-weight: 700;
}

.prim_footer {
  position: relative;
  z-index: 10;
  height: auto;
  margin-top: auto;
  padding-bottom: 10px;
  background-color: #44546a;
}

.text-block-203 {
  padding-bottom: 20px;
  border-bottom: 0px solid #fff;
  color: #fff;
  font-size: 1.5em;
  line-height: 1.5;
}

.div-block-468 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 23vw;
  padding: 5px 10px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0px solid #000;
  border-radius: 100em;
  background-color: #2f3a49;
  font-size: 1px;
}

.image-192 {
  width: 100px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.container-53 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  height: 100%;
  max-width: 80vw;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-162-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ic_burgerdiv {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 20;
  display: none;
  width: 50px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 5em;
  background-color: #fff;
}

.ic_item {
  display: block;
  overflow: hidden;
  width: 15vw;
  height: 30vh;
  margin-top: 0px;
  padding-top: 0vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.div-block-471 {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 100px;
  height: 100px;
}

.text-block-225 {
  margin-top: 40px;
  margin-bottom: 20px;
  background-color: transparent;
  color: #fff;
  font-size: 3em;
  line-height: 1.5;
  text-align: center;
}

.ic_wsk_tool {
  width: 215px;
  max-width: 215px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
}

.ic_no {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  color: #000;
  font-size: 2em;
  font-weight: 600;
}

.div-block-466 {
  display: -ms-grid;
  display: grid;
  width: 420vw;
  height: auto;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
    1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
}

.ic_phasen_wrap {
  max-width: 75em;
  padding-bottom: 60px;
  border-bottom: 0px solid #fff;
}

.ic_phasen_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding-top: 40px;
  padding-bottom: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid #fff;
  opacity: 0;
}

.ic_phasen_item.nobot {
  border-bottom-width: 0px;
}

.hamburger_line2 {
  position: absolute;
  left: 13px;
  top: auto;
  bottom: 17px;
  width: 24px;
  height: 3px;
  background-color: #1b1b1b;
}

.section-114 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-218 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100px;
}

.ic_burgermenu {
  position: fixed;
  left: auto;
  top: 0%;
  right: -300px;
  bottom: 0%;
  z-index: 10;
  display: none;
  width: 300px;
  background-color: #fff;
}

.ic_track {
  position: static;
  z-index: 1;
  width: auto;
  height: 520vw;
  border-top: 0px solid #fff;
  background-color: transparent;
  opacity: 0;
}

.ic_section_phasenmodell {
  height: auto;
  padding-top: 10vh;
  background-color: transparent;
}

.prim_footer_link {
  display: block;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.container-40 {
  max-width: 700px;
  margin-top: 0px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.div-block-330 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-22 {
  position: static;
  left: 10%;
  top: 120px;
  margin-top: 60px;
  margin-bottom: 70px;
  margin-left: 0px;
  background-color: transparent;
  color: #000;
  font-size: 48px;
  line-height: 48px;
  font-weight: 500;
}

.prim_impressum_h2 {
  width: auto;
  margin-top: 40px;
  margin-right: 0px;
  margin-bottom: 20px;
  padding: 10px;
  border-left: 5px solid #696cff;
  border-radius: 6px;
  background-color: #e9e9ff;
  color: #696cff;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.prim_impressum_content {
  padding-top: 0px;
  font-size: 20px;
}

.prim_impressum_content.paddingleft {
  height: 25px;
  padding-left: 2px;
}

.prim_impressum_content._2 {
  height: 27px;
}

.prim_impressum_bezeichner {
  width: 300px;
  margin-top: 0px;
  margin-right: 100px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.prim_body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: #010219;
  color: #fff;
}

.div-block-474 {
  position: relative;
}

.prim_container {
  max-width: 990px;
  padding-right: 0px;
  padding-left: 0px;
}

.home {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-width: none;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-475 {
  width: 310px;
  height: 310px;
}

.prim_main_section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1180px;
  height: auto;
  max-width: 100%;
  padding-top: 40px;
  padding-bottom: 300px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.html-embed {
  box-shadow: 1px 1px 3px 0 #000;
}

.html-embed-2 {
  display: none;
  opacity: 1;
}

.div-block-476 {
  position: static;
  left: 0px;
  right: 0px;
  width: 200px;
  box-shadow: 0 1px 3px 0 #fff;
}

.div-block-478 {
  width: 50px;
}

.text-block-226-copy {
  position: absolute;
  left: 60px;
  top: 140px;
  right: 60px;
  bottom: auto;
  font-size: 26px;
  text-align: center;
}

.div-block-479 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.prim_iconbutton {
  padding: 10px;
}

.prim_iconbutton_img {
  width: 20px;
}

.container-55 {
  max-width: 1200px;
  padding-top: 100px;
}

.image-224 {
  width: 21px;
  height: 21px;
  margin-right: 14px;
}

.components {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.html-embed-4 {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 500;
}

.mobile {
  display: none;
  font-size: 18px;
}

.div-block-481 {
  position: -webkit-sticky;
  position: sticky;
}

.lcs_footer-copy {
  position: relative;
  z-index: 2;
  height: auto;
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #44546a;
}

.div-block-482 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.prim_impressum {
  display: block;
}

.prim_impressum.mobile {
  display: none;
}

.prim_impressum_kategorie {
  margin-bottom: 20px;
  font-weight: 400;
}

.container-56 {
  display: block;
  padding-top: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.prim_fail {
  display: none;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #fca5a5;
  border-radius: 6px;
  color: #fca5a5;
}

.form-2 {
  display: block;
  width: 550px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.prim_item_bezeichner {
  display: block;
  height: auto;
  margin-bottom: 0px;
  padding: 0px;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  border-left-width: 0px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.prim_item_bezeichner.center {
  margin-bottom: 0px;
  border-top: 0px solid #abcfd3;
  text-align: center;
}

.prim_item_bezeichner.center.dark {
  color: #010219;
}

.prim_item_bezeichner.center.dark.padding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.prim_item_bezeichner.center.padding {
  padding-top: 40px;
  padding-bottom: 40px;
}

.landscape {
  display: none;
}

@media screen and (min-width: 1280px) {
  .ic_frame {
    margin-top: 0px;
  }

  .div-block-468 {
    border-width: 0px;
  }

  .ic_item {
    margin-top: 0px;
  }

  .prim_container {
    max-width: 1180px;
  }

  .home {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1440px) {
  .ic_wsk_tool {
    width: 240px;
    max-width: 240px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1920px) {
  .ic_heading_hor {
    height: auto;
    border-width: 0px 0px 1px;
    background-color: #2f3a49;
    box-shadow: none;
    font-size: 30px;
  }

  .ic_heading_hor.pos5 {
    border-width: 0px;
  }

  .ic_heading_hor.pos1 {
    width: 15vw;
    border-bottom-width: 0px;
    box-shadow: none;
    font-size: 30px;
  }

  .ic_heading_hor.pos3 {
    border-width: 0px;
  }

  .ic_heading_hor.pos4 {
    border-width: 0px;
  }

  .ic_heading_hor.pos6 {
    border-width: 0px;
  }

  .ic_heading_hor.pos7 {
    border-width: 0px;
    background-color: #2f3a49;
  }

  .ic_heading_hor.pos2 {
    border-width: 0px;
  }

  .ic_container_title {
    opacity: 0.42;
  }

  .paragraph-43 {
    width: 900px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-left: 5px solid #fff;
    color: #fff;
    font-size: 35px;
    line-height: 50px;
    text-align: left;
    letter-spacing: 5px;
  }

  .image-217 {
    width: 60px;
  }

  .text-block-224 {
    font-size: 30px;
  }

  .ic_wsk_step.maxw40 {
    line-height: 1;
  }

  .ic_h1 {
    font-size: 4.5em;
    letter-spacing: 0.1em;
  }

  .text-block-203 {
    margin-top: 40px;
    font-size: 1.3em;
    letter-spacing: 0.1em;
  }

  .ic_wsk_tool {
    width: 300px;
    max-width: 300px;
    font-size: 18px;
  }

  .ic_no {
    font-size: 1.5em;
  }

  .section-114 {
    padding-bottom: 100px;
  }

  .heading-22 {
    top: 150px;
    font-size: 48px;
  }
}

@media screen and (max-width: 991px) {
  .text-block {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .nav-link-light {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .navbar-dark-nav-button {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .navbar-dark-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    color: #fff;
  }

  .text-block-2 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .footer-thin-container {
    max-width: 1200px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .text-block-7 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .nav-link-light-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .our-services-grid {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    grid-template-areas:
      ". ."
      ". ."
      ". .";
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
    text-align: center;
  }

  .service-icon {
    display: inline-block;
  }

  .plans-and-pricing-container {
    max-width: 1200px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .plan-divider {
    display: block;
    width: 100%;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .get-started-button.in-pricing {
    font-size: 14px;
    line-height: 18px;
  }

  .subscribe-content-wrapper {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .contact-form-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .form-wrap {
    width: auto;
    margin-right: 0px;
    margin-bottom: 60px;
    padding-right: 0px;
    padding-bottom: 60px;
    border-bottom: 1px solid rgba(210, 210, 213, 0.3);
    border-right-style: none;
    text-align: center;
  }

  .contact-form-headline {
    margin-bottom: 40px;
  }

  .error-message {
    width: auto;
  }

  .contact-details-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: -40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .contact-form-paragraph {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .wrap {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }

  .footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    text-align: center;
  }

  .small-paragraph {
    margin-bottom: 5px;
  }

  .small-paragraph.cc-copyright-footer {
    display: block;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    -webkit-align-self: end;
    -ms-flex-item-align: end;
    -ms-grid-row-align: end;
    align-self: end;
  }

  .label.cc-footer-section-headline {
    margin-bottom: 20px;
  }

  .footer-grid-column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    align-self: start;
  }

  .footer-thin-container-2 {
    max-width: 1200px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .erfolg {
    left: 50px;
    top: 908px;
    display: none;
  }

  .email_vergeben {
    left: 50px;
    top: 908px;
    display: none;
  }

  .heading-24 {
    font-size: 40px;
  }

  .navbar-dark-nav-button-_-navigationpage {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .image-220 {
    display: none;
  }

  .ic_heading_hor {
    padding-right: 0vw;
    padding-left: 0vw;
    font-size: 16px;
  }

  .ic_heading_hor.pos5 {
    width: 19vw;
  }

  .ic_heading_hor.pos1 {
    width: 20vw;
    font-size: 18px;
  }

  .ic_heading_hor.pos3 {
    width: 12vw;
  }

  .ic_heading_hor.pos4 {
    width: 14vw;
  }

  .ic_heading_hor.pos6 {
    width: 14vw;
  }

  .ic_heading_hor.pos7 {
    width: 13vw;
  }

  .ic_heading_hor.pos2 {
    width: 12vw;
  }

  .ic_container_phasenmodell {
    max-width: none;
    padding-right: 20px;
    padding-left: 20px;
  }

  .div-block-398 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-469 {
    width: 700vw;
  }

  .ic_section_title {
    height: auto;
  }

  .text-block-104-copy {
    font-size: 20px;
    line-height: 30px;
  }

  .text-block-224 {
    font-size: 20px;
  }

  .container-47-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .image-92-copy {
    width: 50px;
  }

  .image-220-mobil {
    display: block;
  }

  .div-block-465 {
    width: 700vw;
  }

  .ic_phasen_beschreibung {
    width: 55vw;
    font-size: 2em;
  }

  .ic_frame {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .link-block-20 {
    border-radius: 5em;
  }

  .ic_camera {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }

  .div-block-468 {
    width: 38vw;
  }

  .ic_item {
    width: 25vw;
    height: 30vh;
  }

  .div-block-466 {
    width: 700vw;
  }

  .ic_phasen_wrap {
    max-width: none;
    padding-right: 20px;
    padding-left: 20px;
  }

  .section-114 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 60px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-218 {
    display: block;
  }

  .ic_track {
    height: 800vw;
  }

  .container-40 {
    max-width: 700px;
    padding-left: 0px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .heading-22 {
    left: 10%;
    top: 120px;
    margin-left: auto;
  }

  .prim_item_bezeichner {
    padding-right: 20px;
  }

  .prim_item_bezeichner.center.padding {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    padding-bottom: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
}

@media screen and (max-width: 767px) {
  .list-item-table {
    border-bottom: 1px solid #000;
  }

  .list-item-table-produktnummern {
    border-bottom: 1px solid #000;
  }

  .list-item-table-produktnummern-copy {
    border-bottom: 1px solid #000;
  }

  .list-item-table-tafel-heute {
    border-bottom: 1px solid #000;
  }

  .footer-thin-wrapper {
    padding-top: 43px;
    padding-bottom: 43px;
    text-align: center;
  }

  .layout-container {
    text-align: center;
  }

  .our-services-grid {
    grid-row-gap: 60px;
    grid-template-areas:
      "."
      "."
      "."
      "."
      "."
      ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 60px auto 60px auto 60px auto 60px auto 60px auto;
    grid-template-rows: auto auto auto auto auto auto;
  }

  .paragraph-bigger {
    font-size: 16px;
    line-height: 28px;
  }

  .plans-and-pricing-section {
    padding-top: 43px;
    padding-bottom: 43px;
    text-align: center;
  }

  .section-subtitle {
    margin-bottom: 44px;
  }

  .plan-wrapper {
    margin-right: 5%;
    margin-bottom: 34px;
    margin-left: 5%;
  }

  .plan-divider {
    width: 100%;
  }

  .subscribe-content-wrapper {
    padding-right: 4%;
    padding-left: 4%;
  }

  .contact-form-wrap {
    padding: 40px;
  }

  .contact-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .text-field {
    width: auto;
    margin-right: 0px;
    margin-bottom: 10px;
    text-align: center;
  }

  .text-field::-webkit-input-placeholder {
    text-align: center;
  }

  .text-field:-ms-input-placeholder {
    text-align: center;
  }

  .text-field::-ms-input-placeholder {
    text-align: center;
  }

  .text-field::placeholder {
    text-align: center;
  }

  .text-field.cc-textarea {
    margin-bottom: 20px;
  }

  .error-message {
    font-size: 14px;
    line-height: 22px;
  }

  .contact-details-wrap {
    margin-bottom: -30px;
  }

  .wrap {
    padding-right: 4%;
    padding-left: 4%;
  }

  .footer-thin-wrapper-2 {
    padding-top: 43px;
    padding-bottom: 43px;
    text-align: center;
  }

  .anbieter_registrierung2login {
    margin-top: 0px;
  }

  .anbieter_registrierung_submit {
    margin-top: 0px;
  }

  .anbieter_login_submit {
    margin-top: 0px;
  }

  .login_sperrung_more {
    margin-top: 0px;
  }

  .login_sperrung_2login {
    margin-top: 0px;
  }

  .anbieter_kennwort_reset_submit {
    margin-top: 0px;
  }

  .anbieter_registrierung_submit-2 {
    margin-top: 0px;
  }

  .ic_heading_hor {
    left: auto;
    top: 5px;
    width: 20vw;
    height: auto;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    box-shadow: none;
    font-size: 3vh;
    line-height: 1.5;
  }

  .ic_heading_hor.pos1 {
    left: auto;
    top: 5px;
    height: auto;
    max-height: none;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    font-size: 3vh;
  }

  .ic_heading_hor.pos7 {
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    font-size: 3vh;
    line-height: 1.5;
  }

  .ic_heading_hor.pos2 {
    padding: 0.5vh 0vw;
  }

  .div-block-467 {
    height: auto;
    max-height: 15vh;
  }

  .div-block-469 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .image-217 {
    width: auto;
    max-height: 10vh;
  }

  .text-block-224 {
    font-size: 16px;
  }

  .ic_wsk_step {
    font-size: 14px;
  }

  .ic_frame {
    padding-top: 5vh;
  }

  .link-block-20 {
    font-size: 5vw;
  }

  .prim_footer {
    padding-bottom: 0px;
  }

  .div-block-468 {
    max-height: 14vh;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
  }

  .image-192 {
    width: 10vh;
  }

  .container-53 {
    padding-top: 3vh;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .ic_item {
    height: 30vh;
    margin-bottom: -10vh;
  }

  .div-block-471 {
    width: 75px;
    height: 75px;
  }

  .ic_wsk_tool {
    height: auto;
    font-size: 3vh;
    line-height: 1.5;
    text-align: center;
  }

  .div-block-466 {
    height: auto;
    max-height: 80vh;
    grid-row-gap: 0.5vh;
  }

  .image-218 {
    width: 75px;
  }

  .container-40 {
    max-width: 500px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .div-block-330 {
    margin-bottom: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .heading-22 {
    top: 100px;
    margin-bottom: 40px;
    margin-left: auto;
    font-size: 35px;
    line-height: 35px;
  }

  .prim_impressum_h2 {
    margin-bottom: 40px;
  }

  .prim_impressum_bezeichner {
    width: 200px;
    padding-bottom: 0px;
    padding-left: 5px;
  }

  .home {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 100px;
    padding-bottom: 200px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .prim_item_bezeichner {
    padding-right: 0px;
  }
}

@media screen and (max-width: 479px) {
  .text-block {
    padding-left: 20px;
  }

  .nav-link-light:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .navbar-dark-nav-button:hover {
    background-color: #fff;
  }

  .text-block-2 {
    padding-left: 20px;
  }

  .text-block-7 {
    padding-left: 20px;
  }

  .nav-link-light-2:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .price-point {
    text-align: left;
  }

  .subscribe-content-wrapper {
    padding-right: 4%;
    padding-left: 4%;
  }

  .contact-form-wrap {
    padding: 40px 30px;
  }

  .contact-details-wrap {
    margin-bottom: -30px;
  }

  .wrap {
    padding-right: 4%;
    padding-left: 4%;
  }

  .small-paragraph.cc-copyright-footer {
    font-style: italic;
    text-decoration: none;
  }

  .form-block-4 {
    width: 250px;
  }

  .erfolg {
    left: 20px;
    width: 280px;
  }

  .email_vergeben {
    left: 20px;
    width: 280px;
  }

  .heading-24 {
    font-size: 30px;
  }

  .navbar-dark-nav-button-_-navigationpage:hover {
    background-color: #fff;
  }

  .ic_heading_hor {
    width: 30vw;
    font-size: 2vh;
  }

  .ic_heading_hor.pos5 {
    width: 29vw;
  }

  .ic_heading_hor.pos1 {
    width: 30vw;
    font-size: 2vh;
  }

  .ic_heading_hor.pos3 {
    width: 20vw;
  }

  .ic_heading_hor.pos4 {
    width: 23vw;
  }

  .ic_heading_hor.pos6 {
    width: 22vw;
  }

  .ic_heading_hor.pos7 {
    width: 21vw;
    font-size: 2vh;
  }

  .ic_heading_hor.pos2 {
    width: 21vw;
  }

  .ic_container_phasenmodell {
    padding-bottom: 20px;
  }

  .div-block-398 {
    margin-bottom: 10px;
  }

  .div-block-469 {
    width: 1120vw;
  }

  .ic_container_title {
    max-width: 90em;
    padding-bottom: 10px;
  }

  .paragraph-43 {
    width: 80%;
    font-size: 5em;
    line-height: 2;
  }

  .image-217 {
    max-height: 5vh;
  }

  .ic_h2 {
    font-size: 6em;
  }

  .ic_section_title {
    padding-bottom: 2vh;
  }

  .text-block-104-copy {
    font-size: 16px;
    line-height: 25px;
  }

  .container-47-copy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-92-copy {
    width: 50px;
  }

  .div-block-465 {
    width: 1120vw;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .ic_phasen_beschreibung {
    width: auto;
    font-size: 3em;
  }

  .ic_wsk_step.maxw40 {
    max-width: none;
    font-size: 4em;
  }

  .ic_h1 {
    width: 90vw;
    font-size: 7em;
  }

  .text-block-203 {
    font-size: 3em;
  }

  .div-block-468 {
    width: 70vw;
  }

  .container-53 {
    padding-top: 0vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-162-copy {
    padding-bottom: 0px;
  }

  .ic_item {
    width: 40vw;
    height: 40vh;
    margin-bottom: auto;
  }

  .div-block-471 {
    top: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
  }

  .ic_wsk_tool {
    font-size: 2vh;
  }

  .ic_no {
    width: 30px;
    height: 30px;
    font-size: 5em;
  }

  .div-block-466 {
    width: 1120vw;
  }

  .ic_phasen_item {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .image-218 {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 60px;
  }

  .ic_track {
    height: 1220vw;
    opacity: 0;
  }

  .prim_footer_link {
    margin-bottom: 20px;
    font-size: 12px;
  }

  .container-40 {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .div-block-330 {
    margin-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .heading-22 {
    top: 80px;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  .prim_impressum_h2 {
    width: auto;
  }

  .prim_impressum_content {
    margin-bottom: 15px;
    font-size: 14px;
  }

  .prim_impressum_bezeichner {
    width: auto;
    margin-bottom: 5px;
    padding-left: 0px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }

  .prim_body {
    overflow: visible;
  }

  .home {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .mobile {
    display: block;
  }

  .prim_impressum {
    display: none;
  }

  .prim_impressum.mobile {
    display: block;
  }

  .prim_impressum_kategorie {
    font-size: 24px;
  }

  .landscape {
    display: block;
  }
}

#w-node-ddcfabbe-9d08-edf4-19e5-c819ac1a0f40-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4d58f035-77a9-d524-5961-6085b25927a1-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0d7c9667-1087-4581-dead-80c62b549330-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d4dcac4d-210d-26bc-20c9-c022cfa55239-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_03c2b26c-ac15-47fc-e649-791dd314fe7b-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_3834c80a-4ac5-df46-63ef-2c64625c7ed8-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_33a88897-ffbd-a9dc-491e-a80d0f5facbc-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_3b9e66c7-a69a-671d-7337-49a1742da17c-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#bom_komponentenid.w-node-_1a3b44ae-663f-c2d9-4c6a-0570e7a1cd65-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7c09662a-a177-1673-efd0-e817e151a097-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#lcs_bom_kg.w-node-_1a3b44ae-663f-c2d9-4c6a-0570e7a1cd67-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#bom_designatoren.w-node-_1a3b44ae-663f-c2d9-4c6a-0570e7a1cd69-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#bom_anzahl.w-node-_1a3b44ae-663f-c2d9-4c6a-0570e7a1cd6b-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#bom_fitted.w-node-_1a3b44ae-663f-c2d9-4c6a-0570e7a1cd6d-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#bom_lieferlage.w-node-_1a3b44ae-663f-c2d9-4c6a-0570e7a1cd6f-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#bom_anwendung.w-node-_1a3b44ae-663f-c2d9-4c6a-0570e7a1cd71-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_1a3b44ae-663f-c2d9-4c6a-0570e7a1cd73-f251b54d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_23336171-ac61-6289-9f09-40b197f3eb98-0951b556 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_23336171-ac61-6289-9f09-40b197f3eb9a-0951b556 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_23336171-ac61-6289-9f09-40b197f3eba6-0951b556 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_8cff7d30-77ee-96b5-6d26-82c2812f75e3-0951b556 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#komponenten_komponentenid.w-node-f65f6fd6-53f0-2935-59ba-6c625fab4617-0951b556 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#lcs_komponenten_komponentengruppen.w-node-f65f6fd6-53f0-2935-59ba-6c625fab4619-0951b556 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#komponenten_komponentengruppe.w-node-f65f6fd6-53f0-2935-59ba-6c625fab461a-0951b556 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: start;
  justify-self: start;
  -ms-grid-row-align: center;
  align-self: center;
}

#lcs_komponenten_edit.w-node-f65f6fd6-53f0-2935-59ba-6c625fab461c-0951b556 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f65f6fd6-53f0-2935-59ba-6c625fab461d-0951b556 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_23336171-ac61-6289-9f09-40b197f3eb98-2f51b558 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_23336171-ac61-6289-9f09-40b197f3eb9a-2f51b558 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d92b6720-87e5-ebf5-bc24-f66615930c7b-2f51b558 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_23336171-ac61-6289-9f09-40b197f3eba6-2f51b558 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6d616428-07e2-13c9-f679-45c8f62a5da5-2f51b558 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#lt_ltid.w-node-cc83040c-fef1-3e59-837e-0310488aac8e-2f51b558 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#lcs_lt_artdiv.w-node-_39abbad4-5f24-8add-9a7a-390d91a8cfc3-2f51b558 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: auto;
  justify-self: auto;
}

#lt_ltart.w-node-cc83040c-fef1-3e59-837e-0310488aac90-2f51b558 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: start;
  justify-self: start;
  -ms-grid-row-align: center;
  align-self: center;
}

#lt_lagerort.w-node-_7d09111a-2b87-a531-76ff-f6179653150c-2f51b558 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#lcs_lt_edit.w-node-f28e57b3-6d45-3c27-b9c6-f780dd3d59d9-2f51b558 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_372f5cdd-1d74-0ae0-c8e8-3db5f62bf1f3-2f51b558 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aaf05-f151b581 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aaf07-f151b581 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aaf09-f151b581 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aaf0b-f151b581 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aaf0d-f151b581 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aaf0f-f151b581 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aafa0-f151b581 {
  -ms-grid-column: 7;
  grid-column-start: 7;
  -ms-grid-column-span: 22;
  grid-column-end: 29;
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aafa5-f151b581 {
  -ms-grid-column: 6;
  grid-column-start: 6;
  -ms-grid-column-span: 19;
  grid-column-end: 25;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aafaa-f151b581 {
  -ms-grid-column: 6;
  grid-column-start: 6;
  -ms-grid-column-span: 23;
  grid-column-end: 29;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aafaf-f151b581 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 28;
  grid-column-end: 29;
  -ms-grid-row: 4;
  grid-row-start: 4;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
}

#w-node-_815fed8a-735e-a745-a316-92dba27aafb4-f151b581 {
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 28;
  grid-column-end: 29;
  -ms-grid-row: 5;
  grid-row-start: 5;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
}

.ui-loading .ui-loader,
.ui-loader {
  display: none;
}

/* BUTTONS */
/* BUTTONS */

.prim_buttons {
  padding-right: 0px;
  padding-left: 0px;
}

.prim_buttons_onlytext_enabled {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_onlytext_disabled {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_button_primary_disabled {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #abcfd3;
  border-radius: 28px;
  background-color: rgba(171, 207, 211, 0.16);
  opacity: 0.6;
  color: #abcfd3;
}

.prim_button_secondary_disabled {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #cbd5e1;
  border-radius: 28px;
  background-color: transparent;
  opacity: 0.6;
  color: #cbd5e1;
}

.prim_button_success_disabled {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #86efac;
  border-radius: 28px;
  background-color: transparent;
  opacity: 0.6;
  color: #86efac;
}

.prim_button_info_disabled {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #93c5fd;
  border-radius: 28px;
  background-color: transparent;
  opacity: 0.6;
  color: #93c5fd;
}

.prim_button_warning_disabled {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #fcd34d;
  border-radius: 28px;
  background-color: transparent;
  opacity: 0.6;
  color: #fcd34d;
}

.prim_button_help_disabled {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #d8b4fe;
  border-radius: 28px;
  background-color: transparent;
  opacity: 0.6;
  color: #d8b4fe;
}

.prim_button_danger_disabled {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #fca5a5;
  border-radius: 28px;
  background-color: transparent;
  opacity: 0.6;
  color: #fca5a5;
}

.prim_buttons_textandicon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_button_textandicon {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8.5px 17.5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  border-style: solid;
  border-width: 1px;
  border-color: #abcfd3;
  border-radius: 28px;
  background-color: transparent;
  color: #abcfd3;
}

.prim_button_textandicon.disabled {
  opacity: 0.6;
}

.prim_button_textandicon.primary {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  background-color: rgba(171, 207, 211, 0.16);
}

.prim_button_textandicon.primary.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.prim_button_textandicon.secondary {
  border-color: #cbd5e1;
  background-color: transparent;
  color: #cbd5e1;
}

.prim_button_textandicon.secondary.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.prim_button_textandicon.secondary.highlighted {
  box-shadow: -1px -1px 20px 0 #cbd5e1, 1px 1px 20px 0 #cbd5e1;
}

.prim_button_textandicon.success {
  border-color: #86efac;
  background-color: transparent;
  color: #86efac;
}

.prim_button_textandicon.success.highlighted {
  box-shadow: -1px -1px 20px 0 #86efac, 1px 1px 20px 0 #86efac;
}

.prim_button_textandicon.info {
  border-color: #93c5fd;
  background-color: transparent;
  color: #93c5fd;
}

.prim_button_textandicon.info.highlighted {
  box-shadow: -1px -1px 20px 0 #93c5fd, 1px 1px 20px 0 #93c5fd;
}

.prim_button_textandicon.warning {
  border-color: #fcd34d;
  color: #fcd34d;
}

.prim_button_textandicon.warning.highlighted {
  box-shadow: -1px -1px 20px 0 #fcd34d, 1px 1px 20px 0 #fcd34d;
}

.prim_button_textandicon.help {
  border-color: #d8b4fe;
  color: #d8b4fe;
}

.prim_button_textandicon.help.highlighted {
  box-shadow: -1px -1px 20px 0 #d8b4fe, 1px 1px 20px 0 #d8b4fe;
}

.prim_button_textandicon.danger {
  border-color: #fca5a5;
  color: #fca5a5;
}

.prim_button_textandicon.danger.highlighted {
  box-shadow: -1px -1px 20px 0 #fca5a5, 1px 1px 20px 0 #fca5a5;
}

.prim_button_textandicon.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.prim_button_textandicon.highlighted {
  box-shadow: -1px -1px 20px 0 #abcfd3, 1px 1px 20px 0 #abcfd3;
}

.prim_buttons_onlytext_enabled-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_textandicon_enabled_reverse {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_textandicon_disabled {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_textandicon_disabled_reverse {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_textandico {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_textandicon_enabled {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_onlyicon_enabled {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_button_onlyicon {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8.5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  border-style: solid;
  border-width: 1px;
  border-color: #abcfd3;
  border-radius: 28px;
  background-color: transparent;
  color: #abcfd3;
}

.prim_button_onlyicon.disabled {
  opacity: 0.6;
}

.prim_button_onlyicon.primary {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  background-color: rgba(171, 207, 211, 0.16);
}

.prim_button_onlyicon.primary.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.prim_button_onlyicon.secondary {
  border-color: #cbd5e1;
  background-color: transparent;
  color: #cbd5e1;
}

.prim_button_onlyicon.secondary.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.prim_button_onlyicon.success {
  border-color: #86efac;
  background-color: transparent;
  color: #86efac;
}

.prim_button_onlyicon.info {
  border-color: #93c5fd;
  background-color: transparent;
  color: #93c5fd;
}

.prim_button_onlyicon.warning {
  border-color: #fcd34d;
  color: #fcd34d;
}

.prim_button_onlyicon.help {
  border-color: #d8b4fe;
  color: #d8b4fe;
}

.prim_button_onlyicon.danger {
  border-color: #fca5a5;
  color: #fca5a5;
}

.prim_button_onlyicon.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.prim_button_onlyicon.highlighted {
  box-shadow: -1px -1px 20px 0 #abcfd3, 1px 1px 20px 0 #abcfd3;
}

.prim_buttons_onlyicon_disabled {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_onlytext_highlighted {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_textandicon_highlighted {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_buttons_textandicon_highlighted_reverse {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.prim_button {
  margin-right: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px solid #cbd5e1;
  border-radius: 6em;
  background-color: rgba(203, 213, 225, 0.16);
  color: #fff;
  font-size: 16px;
}

.prim_button_onlytext {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #abcfd3;
  border-radius: 28px;
  background-color: transparent;
  color: #abcfd3;
}

.prim_button_onlytext.disabled {
  opacity: 0.6;
}

.prim_button_onlytext.primary {
  display: block;
  border-color: #abcfd3;
  background-color: rgba(171, 207, 211, 0.16);
  color: #abcfd3;
}

.prim_button_onlytext.primary.floatleft {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  float: left;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.prim_button_onlytext.primary.highlighted {
  box-shadow: -1px -1px 20px 0 #abcfd3, 1px 1px 20px 0 #abcfd3;
}

.prim_button_onlytext.primary.hidden {
  display: none;
}

.prim_button_onlytext.secondary {
  border-color: #cbd5e1;
  background-color: transparent;
  color: #cbd5e1;
}

.prim_button_onlytext.secondary.highlighted {
  box-shadow: -1px -1px 20px 0 #cbd5e1, 1px 1px 20px 0 #cbd5e1;
}

.prim_button_onlytext.success {
  border-color: #86efac;
  background-color: transparent;
  color: #86efac;
}

.prim_button_onlytext.success.highlighted {
  box-shadow: -1px -1px 20px 0 #86efac, 1px 1px 20px 0 #86efac;
}

.prim_button_onlytext.info {
  border-color: #93c5fd;
  background-color: transparent;
  color: #93c5fd;
}

.prim_button_onlytext.info.highlighted {
  box-shadow: -1px -1px 20px 0 #93c5fd, 1px 1px 20px 0 #93c5fd;
}

.prim_button_onlytext.warning {
  border-color: #fcd34d;
  color: #fcd34d;
}

.prim_button_onlytext.warning.highlighted {
  box-shadow: -1px -1px 20px 0 #fcd34d, 1px 1px 20px 0 #fcd34d;
}

.prim_button_onlytext.help {
  border-color: #d8b4fe;
  color: #d8b4fe;
}

.prim_button_onlytext.help.highlighted {
  box-shadow: -1px -1px 20px 0 #d8b4fe, 1px 1px 20px 0 #d8b4fe;
}

.prim_button_onlytext.danger {
  border-color: #fca5a5;
  color: #fca5a5;
}

.prim_button_onlytext.danger.highlighted {
  box-shadow: -1px -1px 20px 0 #fca5a5, 1px 1px 20px 0 #fca5a5;
}

.prim_button_onlytext.highlighted {
  box-shadow: -1px -1px 20px 0 #abcfd3, 1px 1px 20px 0 #abcfd3;
}

.prim_button_secondary_onlytext {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #cbd5e1;
  border-radius: 28px;
  background-color: transparent;
  color: #cbd5e1;
}

.prim_button_success_onlytext {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #86efac;
  border-radius: 28px;
  background-color: transparent;
  color: #86efac;
}

.prim_button_info_onlytext {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #93c5fd;
  border-radius: 28px;
  background-color: transparent;
  color: #93c5fd;
}

.prim_button_warning_onlytext {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #fcd34d;
  border-radius: 28px;
  background-color: transparent;
  color: #fcd34d;
}

.prim_button_help_onlytext {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #d8b4fe;
  border-radius: 28px;
  background-color: transparent;
  color: #d8b4fe;
}

.prim_button_danger_onlytext {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10.5px 17.5px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #fca5a5;
  border-radius: 28px;
  background-color: transparent;
  color: #fca5a5;
}

@media screen and (max-width: 479px) {
  .prim_button_onlytext.primary.marginbottommobile {
    margin-bottom: 20px;
  }

  .prim_button_onlytext.secondary.marginbottommobile {
    margin-bottom: 20px;
  }
}

.allroutes {
  min-height: 95vh;
}
