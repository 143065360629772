.bi-grad {
  background-image: linear-gradient(
    94deg,
    transparent,
    #abcfd3 50%,
    transparent
  );
}

.bi-grad-tablet-left {
  background-image: linear-gradient(
    177deg,
    transparent,
    #abcfd3 50%,
    transparent
  );
}

.bi-grad-tablet-right {
  background-image: linear-gradient(
    183deg,
    transparent,
    #abcfd3 50%,
    transparent
  );
}

.bi-grad-ls-left {
  background-image: linear-gradient(
    178deg,
    transparent,
    #abcfd3 50%,
    transparent
  );
}

.bi-grad-ls-right {
  background-image: linear-gradient(
    182deg,
    transparent,
    #abcfd3 50%,
    transparent
  );
}

.bi-grad-mobile-left {
  background-image: linear-gradient(
    179.5deg,
    transparent,
    #abcfd3 50%,
    transparent
  );
}

.bi-grad-mobile-right {
  background-image: linear-gradient(
    180.5deg,
    transparent,
    #abcfd3 50%,
    transparent
  );
}

.ansatzfelder_trenner_hor1 {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(transparent),
    color-stop(50%, #4b4b4b),
    to(transparent)
  );
  background-image: linear-gradient(
    270deg,
    transparent,
    #4b4b4b 50%,
    transparent
  );
}

.ansatzfelder_trenner_hor1_tablet {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(transparent),
    color-stop(50%, #4b4b4b),
    to(transparent)
  );
  background-image: linear-gradient(
    0deg,
    transparent,
    #4b4b4b 50%,
    transparent
  );
}

.ansatzfelder_trenner_hor2 {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(transparent),
    color-stop(50%, #4b4b4b),
    to(transparent)
  );
  background-image: linear-gradient(
    270deg,
    transparent,
    #4b4b4b 50%,
    transparent
  );
}

.ansatzfelder_trenner_hor2_tablet {
  left: auto;
  top: 0%;
  right: 40%;
  bottom: 0%;
  display: none;
  width: 1px;
  height: auto;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(transparent),
    color-stop(50%, #4b4b4b),
    to(transparent)
  );
  background-image: linear-gradient(
    0deg,
    transparent,
    #4b4b4b 50%,
    transparent
  );
}

.ansatzfelder_trenner_vert_1 {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(4%, transparent),
    color-stop(50%, #4b4b4b),
    color-stop(95%, rgba(5, 5, 5, 0.06)),
    to(transparent)
  );
  background-image: linear-gradient(
    0deg,
    transparent 4%,
    #4b4b4b 50%,
    rgba(5, 5, 5, 0.06) 95%,
    transparent
  );
}

.ansatzfelder_trenner_vert_2 {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(8%, transparent),
    color-stop(47%, #4b4b4b),
    color-stop(92%, rgba(5, 5, 5, 0.06)),
    to(transparent)
  );
  background-image: linear-gradient(
    0deg,
    transparent 8%,
    #4b4b4b 47%,
    rgba(5, 5, 5, 0.06) 92%,
    transparent
  );
}

.ansatzfelder_trenner_vert_3 {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(12%, transparent),
    color-stop(43%, #4b4b4b),
    color-stop(89%, rgba(5, 5, 5, 0.06)),
    to(transparent)
  );
  background-image: linear-gradient(
    0deg,
    transparent 12%,
    #4b4b4b 43%,
    rgba(5, 5, 5, 0.06) 89%,
    transparent
  );
}

.ansatzfelder_trenner_vert_4 {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(15%, transparent),
    color-stop(40%, #4b4b4b),
    color-stop(85%, rgba(5, 5, 5, 0.06)),
    to(transparent)
  );
  background-image: linear-gradient(
    0deg,
    transparent 15%,
    #4b4b4b 40%,
    rgba(5, 5, 5, 0.06) 85%,
    transparent
  );
}

@media screen and (max-width: 991px) {
  .ansatzfelder_trenner_vert {
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(transparent),
      color-stop(50%, #4b4b4b),
      color-stop(100%, rgba(5, 5, 5, 0.06)),
      to(transparent)
    );
    background-image: linear-gradient(
      90deg,
      transparent,
      #4b4b4b 50%,
      rgba(5, 5, 5, 0.06) 100%,
      transparent
    );
  }
}
