.prim-trennergradient-1 {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(#4b4b4b)
  );
  background-image: linear-gradient(180deg, transparent, #4b4b4b);
}

.prim-trennergradient-2 {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(transparent),
    color-stop(50%, #4b4b4b),
    to(transparent)
  );
  background-image: linear-gradient(
    270deg,
    transparent,
    #4b4b4b 50%,
    transparent
  );
}

.prim-trennergradient-3 {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#4b4b4b),
    to(transparent)
  );
  background-image: linear-gradient(180deg, #4b4b4b, transparent);
}
